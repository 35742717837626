import React, {useState} from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";
import LoadingButton from "../../../../components/button-component/loading-button";
import StyledDialog, {StyledDialogCell} from "../../../../components/dialog-component/styled-dialog";
import AboutYellowIcon from "../../../../../assets/icons/about-yellow-icon";
import LicencesApi from "../../../../../apis/licences/licences-api";
import useUser from "../../../../../custom-hook/use-user";
import {ErrorToast} from "../../../../../Util/util";

async function asyncLicencesAllocate(installationId,params,token){
    return await LicencesApi.autoAllocateLicence(installationId,params,token)
}
function AutoAllocateLicences(props) {
    const {open,onClose,afterSave,unallocatedLicenceCount,unAssinedChargepoint}=props
    const {getUserInstallationId,getToken}=useUser()
    const [state,setState]=useState({loading:{unallocateLicence:false}})
    const {loading}=state
    function onSubmit(){
        setState(prevState => ({...prevState,loading: {...prevState.loading,unallocateLicence: true}}))

        asyncLicencesAllocate(getUserInstallationId(),{},getToken()).then((res)=>{
            setState(prevState => ({...prevState,loading: {...prevState.loading,unallocateLicence: false}}))
            afterSave()
        }).catch((e)=>{
            setState(prevState => ({...prevState,loading: {...prevState.loading,unallocateLicence: false}}))
            ErrorToast(e)
        })

    }


    return (
        <>
            <StyledDialog title={"Auto allocate licences"} open={open} onClose={() => onClose()}>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <Box sx={{display: "flex", gap: 2, flexDirection: "column", width: "25vw"}}>
                        <Box sx={{display:"flex",flexDirection:"column",gap:0.3}}>
                            <Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Unassigned licences will be auto allocated between chargepoints</Typography>
                        </Box>
                        <Box sx={{display:"flex",flexDirection:"column",gap:0.3}}>
                            <Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Unallocated licences: <Typography variant={"subtitle2"} fontWeight={"bold"} color={"text.primay"}>{unallocatedLicenceCount}</Typography></Typography>
                            <Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Unassigned chargepoints: <Typography variant={"subtitle2"} fontWeight={"bold"} color={"text.primay"}>{unAssinedChargepoint}                                                                </Typography></Typography>

                        </Box>
                    </Box>
                </StyledDialogCell>
                <Divider/>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <LoadingButton disabled={unAssinedChargepoint==0 || unallocatedLicenceCount==0} loading={loading.unallocateLicence } color={"primary"}  onClick={() => onSubmit()} label={"Allocate licences"}/>
                </StyledDialogCell>
            </StyledDialog>

        </>
    );
}

export default AutoAllocateLicences;