import React from 'react';

function DeleteButtonIcon(props) {
    const {fontSize="32px"}=props
    return (
        <>
        <svg width="32" height={fontSize} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5 10.9833C20.725 10.7083 17.9333 10.5667 15.15 10.5667C13.5 10.5667 11.85 10.65 10.2 10.8167L8.5 10.9833" stroke="#F0142F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.0833 10.1417L13.2666 9.05008C13.3999 8.25841 13.4999 7.66675 14.9083 7.66675H17.0916C18.4999 7.66675 18.6083 8.29175 18.7333 9.05841L18.9166 10.1417" stroke="#F0142F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.7084 13.6167L21.1667 22.0084C21.0751 23.3167 21.0001 24.3334 18.6751 24.3334H13.3251C11.0001 24.3334 10.9251 23.3167 10.8334 22.0084L10.2917 13.6167" stroke="#F0142F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.6084 19.75H17.3834" stroke="#F0142F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.9167 16.4167H18.0834" stroke="#F0142F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#F0142F"/>
        </svg>
        </>

    );
}

export default DeleteButtonIcon;