import {getUserRefreshTokenToCache, getUserToCache, removeUserToCache} from "./cache-helper";
import AboutApi from "../apis/authorization/login-api";
import {cloneDeep} from "lodash";
import INITIAL_STATE_USER_SLICE from "../slice/state.userSlice"
let intialStateAuthUser={
    ...INITIAL_STATE_USER_SLICE
}

export async function initializeAppState() {
    let authUser=getUserToCache()
    if(authUser){
        intialStateAuthUser=cloneDeep(authUser)
        let refreshToken=getUserRefreshTokenToCache()
        try {
            let token=await AboutApi.getAccessToken({refreshToken:refreshToken},null)
            token=token.data.token
            intialStateAuthUser.token=token
            intialStateAuthUser.isAuthenticated=true
            intialStateAuthUser.refreshToken=refreshToken
        }catch (e) {
            removeUserToCache()
            intialStateAuthUser.token=null
            intialStateAuthUser.isAuthenticated=false
            intialStateAuthUser.refreshToken=null
        }
    }
    return {authUser:intialStateAuthUser}

}