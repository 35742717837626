import React from 'react';

function UnassignLicenceIcon(props) {
    const {fontSize="32px"}=props
    return (
        <>
            <svg width="32" height={fontSize} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.5605 9.28098C24.9548 8.20028 23.806 7.5293 22.5627 7.5293C21.9777 7.5293 21.3981 7.6809 20.8881 7.96757L14.4368 11.5889L15.1789 12.9116L21.6305 9.29028C21.9159 9.13044 22.2383 9.0459 22.5638 9.0459C23.2585 9.0459 23.8999 9.42023 24.2381 10.0232C24.488 10.4687 24.5492 10.9853 24.4103 11.4782C24.2721 11.9711 23.9504 12.3809 23.5049 12.6308L17.6569 15.9124C17.5455 15.1999 17.2131 14.5427 16.693 14.0222C15.7759 13.1062 14.3223 12.782 13.1013 13.2203L12.5734 13.4104L13.0862 14.8369L13.6163 14.6478C14.2982 14.3996 15.1098 14.5846 15.621 15.0947C15.9159 15.3899 16.1025 15.7639 16.1616 16.1692C16.1369 16.1634 16.1121 16.1572 16.0884 16.1503C15.5945 16.0114 15.1854 15.6894 14.9352 15.2421L14.6595 14.7551L13.3358 15.4989L13.6132 15.986C14.0615 16.7859 14.7943 17.3633 15.6784 17.6115C15.709 17.6194 15.7399 17.628 15.7709 17.6352C15.7241 17.6944 15.6743 17.7507 15.6213 17.8037L10.787 22.6383C10.4254 22.9996 9.94417 23.1983 9.43232 23.1983C8.92013 23.1983 8.4389 22.9996 8.07797 22.6383C7.71705 22.2771 7.51838 21.7965 7.51838 21.284C7.51838 20.7718 7.71705 20.291 8.07797 19.93L12.2768 15.7316L11.204 14.6588L7.00556 18.8572C6.35716 19.5061 6 20.3679 6 21.284C6 22.2007 6.35716 23.0621 7.00543 23.7111C7.65408 24.3598 8.51548 24.7162 9.43224 24.7162C10.349 24.7162 11.2104 24.3598 11.859 23.7111L16.6944 18.8768C17.0485 18.5213 17.3207 18.0917 17.4912 17.6204C17.7714 17.5458 18.0377 17.4386 18.2852 17.299L24.2474 13.9534C25.0456 13.5048 25.6223 12.7717 25.8702 11.8886C26.1183 11.0052 26.0083 10.0788 25.5605 9.28098Z" fill="#F0142F"/>
                <path d="M13.3239 11.9943C13.5143 11.9595 13.6415 11.7767 13.6068 11.5859L12.8835 7.57102C12.8488 7.37992 12.6652 7.25272 12.4748 7.28676C12.2841 7.32148 12.1565 7.50433 12.1912 7.69581L12.9148 11.71C12.9492 11.9015 13.1324 12.0283 13.3239 11.9943Z" fill="#F0142F"/>
                <path d="M7.84147 14.1098L11.856 13.3858C12.0471 13.3511 12.1743 13.1683 12.1396 12.9775C12.1049 12.786 11.922 12.6585 11.7316 12.6932L7.71668 13.4175C7.52626 13.4512 7.39872 13.635 7.43343 13.8258C7.46748 14.017 7.65105 14.1441 7.84147 14.1098Z" fill="#F0142F"/>
                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#F0142F"/>
            </svg>

        </>
    );
}

export default UnassignLicenceIcon;