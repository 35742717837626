import React from 'react';

function DisableAssignLicenceIcon(props) {
    const {fontSize}=props
    return (
        <>
            <svg width="32" height={fontSize} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="4" fill="#ECF2FF"/>
                <g clip-path="url(#clip0_1608_140869)">
                    <path d="M14.2912 21.2443L11.9345 23.601C10.9575 24.5781 9.37523 24.5781 8.39902 23.6012C7.42261 22.6248 7.42261 21.0424 8.39882 20.0662L13.113 15.352C14.0892 14.3757 15.6716 14.3757 16.6479 15.352C16.9733 15.6774 17.5009 15.6774 17.8264 15.352C18.1518 15.0266 18.1518 14.4989 17.8264 14.1735C16.1993 12.5463 13.5616 12.5463 11.9345 14.1735L7.22034 18.8876C5.59322 20.5148 5.59322 23.1524 7.22034 24.7795C8.84726 26.4075 11.4851 26.4075 13.1131 24.7795L15.4698 22.4228C15.7952 22.0974 15.7952 21.5697 15.4698 21.2443C15.1443 20.9189 14.6166 20.9189 14.2912 21.2443Z" fill="#89ABFF"/>
                    <path d="M24.78 7.22034C23.1528 5.59322 20.5143 5.59322 18.8872 7.22034L16.0597 10.0479C15.7343 10.3733 15.7343 10.9009 16.0597 11.2264C16.3851 11.5518 16.9128 11.5518 17.2382 11.2264L20.0658 8.39886C21.042 7.42261 22.6252 7.42261 23.6014 8.39886C24.5777 9.37508 24.5777 10.9575 23.6014 11.9337L18.4164 17.1188C17.4402 18.095 15.8578 18.095 14.8816 17.1188C14.5561 16.7933 14.0285 16.7933 13.7031 17.1188C13.3776 17.4442 13.3776 17.9718 13.7031 18.2973C15.3302 19.9244 17.9678 19.9244 19.5949 18.2973L24.78 13.1122C26.4071 11.4851 26.4071 8.84746 24.78 7.22034Z" fill="#89ABFF"/>
                </g>
                <defs>
                    <clipPath id="clip0_1608_140869">
                        <rect width="20" height="20" fill="white" transform="translate(6 6)"/>
                    </clipPath>
                </defs>
            </svg>

        </>
    );
}

export default DisableAssignLicenceIcon;