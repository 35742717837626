import React from 'react';

function ActivityLogIcon(props) {
    const {fontSize}=props
    return (
        <>
            <svg width="40" height={fontSize} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="4" fill="#1E5EFF"/>
                <path d="M16 10V13" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24 10V13" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 19H23" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 23H20" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M23 30H17C12 30 11 27.94 11 23.82V17.65C11 12.95 12.67 11.69 16 11.5H24C27.33 11.68 29 12.95 29 17.65V24" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M29 24L23 30V27C23 25 24 24 26 24H29Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>


        </>
    );
}

export default ActivityLogIcon;