import React, {useState} from 'react';
import {isEmailValid} from "../../../helper/helper";
import {Avatar, Box, TextField, Typography} from "@mui/material";
import KeyIcon from "../../../assets/icons/forgot-password/key-icon.svg";
import StyledLinkComponent from "../../../components/styled-link-component";
import LoadingButton from "../../components/button-component/loading-button";
import CommonRightSidebar from "../components/common-right-sidebar";
import EmailSubmit from "./email-submit";
import ForgotPasswordApi from "../../../apis/authorization/forgot-password-api"
import {toast} from "react-toastify";
import {ErrorToast} from "../../../Util/util";

function ForgotPassword(props) {
    const [state, setState] = useState({loading: false, formData: {email: ""}, error: {email: false}})
    const [emailSubmit, setEmailSubmit] = useState(false)
    const {error, loading, formData} = state

    function validateForm() {
        let validEmail = isEmailValid(formData.email)
        setState(prevState => ({...prevState, error: {...prevState.error, email: !validEmail}}))
        return (validEmail)
    }

    function onSubmit() {
        if (validateForm()) {
            setState(prevState => ({...prevState,loading: true}))
            const params={userName:formData.email}
            ForgotPasswordApi.sendForgotPasswordRequest(params).then((res)=>{
                setState(prevState => ({...prevState,loading: false}))
                setEmailSubmit(true)
            }).catch((e)=>{
                ErrorToast(e)
                setState(prevState => ({...prevState,loading: false}))

            })
        }

    }


    function onFormDataChange(field, value) {
        setState(prevState => ({...prevState, formData: {...prevState.formData, [field]: value}}))
    }

    if (emailSubmit) {
        return <EmailSubmit formData={formData}/>
    }
    return (
        <>
            <Box sx={{display: "flex", flexFlow: "row", height: "100vh"}}>
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
                     flex={"1 1"}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "15px",
                        width: "60%"
                    }}
                         flex={"1 1"}>
                        <Box flex={"fit-content 0"} display={"flex"} gap={"5px"} alignItems={"center"}>
                            <Avatar src={KeyIcon} variant="square"/>
                            <Typography variant={"h5"}>Reset password</Typography>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Email</Typography>
                            <TextField onChange={(e) => onFormDataChange("email", e.target.value)} error={error.email}
                                       value={formData.email}
                                       helperText={error.email ? "Please enter the valid email" : ""}
                                       placeholder={"example@gmail.com"}/>
                        </Box>


                        <Box flex={"fit-content 0"} display={"flex"} gap={"20px"}>
                            <LoadingButton
                                loading={loading} onClick={onSubmit} size={"large"}
                                sx={{textTransform: 'capitalize', width: "100%"}} color={"primary"}
                                variant={"contained"} label={"Reset password"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} justifyContent={"space-between"} gap={"5px"}>

                            <Box display={"flex"} gap={"4px"}>
                                <StyledLinkComponent path={"/login"}>Back to login</StyledLinkComponent>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <CommonRightSidebar/>


            </Box>
        </>
    );
}

export default ForgotPassword;