import React from 'react';

function DollarErrorIcon(props) {
    const {fontSize="32px"}=props
    return (
        <>
            <svg width="32" height={fontSize} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.3746 12.7324V15.1158L14.5329 14.8241C14.1079 14.6741 13.8496 14.5324 13.8496 13.8074C13.8496 13.2158 14.2913 12.7324 14.8329 12.7324H15.3746Z" fill="#F0142F"/>
                <path d="M18.15 18.1911C18.15 18.7828 17.7083 19.2661 17.1667 19.2661H16.625V16.8828L17.4667 17.1745C17.8917 17.3245 18.15 17.4661 18.15 18.1911Z" fill="#F0142F"/>
                <path d="M19.491 7.66602H12.5077C9.47435 7.66602 7.66602 9.47435 7.66602 12.5077V19.491C7.66602 22.5243 9.47435 24.3327 12.5077 24.3327H19.491C22.5243 24.3327 24.3327 22.5243 24.3327 19.491V12.5077C24.3327 9.47435 22.5243 7.66602 19.491 7.66602ZM17.8827 15.9993C18.5327 16.2243 19.3993 16.6993 19.3993 18.191C19.3993 19.4743 18.3993 20.516 17.166 20.516H16.6243V20.9993C16.6243 21.341 16.341 21.6243 15.9993 21.6243C15.6577 21.6243 15.3743 21.341 15.3743 20.9993V20.516H15.0743C13.7077 20.516 12.5993 19.3577 12.5993 17.941C12.5993 17.5993 12.8743 17.316 13.2243 17.316C13.566 17.316 13.8493 17.5993 13.8493 17.941C13.8493 18.6743 14.3993 19.266 15.0743 19.266H15.3743V16.441L14.116 15.9993C13.466 15.7743 12.5993 15.2993 12.5993 13.8077C12.5993 12.5243 13.5993 11.4827 14.8327 11.4827H15.3743V10.9993C15.3743 10.6577 15.6577 10.3743 15.9993 10.3743C16.341 10.3743 16.6243 10.6577 16.6243 10.9993V11.4827H16.9243C18.291 11.4827 19.3993 12.641 19.3993 14.0577C19.3993 14.3993 19.1243 14.6827 18.7743 14.6827C18.4327 14.6827 18.1493 14.3993 18.1493 14.0577C18.1493 13.3243 17.5993 12.7327 16.9243 12.7327H16.6243V15.5577L17.8827 15.9993Z" fill="#F0142F"/>
                <path d="M6.64648 24.6465L24.6465 6.64648" stroke="white" stroke-linecap="round"/>
                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#F0142F"/>
            </svg>

        </>
    );
}

export default DollarErrorIcon;