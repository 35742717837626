import CryptoJS from "crypto-js";
export function setToken(token) {
    localStorage.setItem("token", token)
}

export function getToken() {
    return localStorage.getItem("token")
}
const secKey="varian@1230"
const encryptText = (plainText, secretKey) => {
    return CryptoJS.AES.encrypt(plainText, secretKey).toString();
};
export function setUserOnCache(user) {
    let encryptedText=encryptText(btoa(user),secKey)
    localStorage.setItem("__user__", encryptedText)
}

export function removeUserToCache(user) {
    localStorage.removeItem("__user__")
}

const decryptText = (cipherText, secretKey) => {
    try {
        const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
        const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        if (!decryptedText) {
            return ""
        }
        return decryptedText;

    } catch (error) {
        return "";
    }
};
export function getUserToCache() {
    let user = localStorage.getItem("__user__")
    let decriptedText=decryptText(user,secKey)
    if (user) {
        try {
            let user= JSON.parse(atob(decriptedText))
            // alert(JSON.stringify(user))
            return user
        } catch (e) {

            return null
        }
    }
    return null
}


export function getUserRefreshTokenToCache() {
    let user = getUserToCache()
    if (user) {
        return user.refreshToken
    }
    return null
}