import React from "react";
import {Typography, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MenuButton from "./menu-button";
const Accordion = styled((props) => (<MuiAccordion disableGutters elevation={0} square {...props} />))(({theme}) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    width: "100%",
    borderBottom: "none",
    background: "#F5F6FA"
}));

const AccordionSummary = styled((props) => (<MuiAccordionSummary expandIcon={<ArrowDropUpRoundedIcon
    sx={{color: props.isSelected ? "#fff" : "", fontSize: '2rem'}}/>}{...props}/>))(({theme}) => ({
    flexDirection: 'row',
    margin: "0px 0px",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: "0px 0px",
        marginLeft: theme.spacing(0),
        display: "flex",
        gap: "3px"

    },
    minHeight: "39px"
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({

    padding: "0px",
    margin: "0px 0px"
}));
function DropDownMenuComponent(props) {
    const {data, selectedMenu, navigateUser} = props
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const theme = useTheme()
    const isSelected = selectedMenu.includes(data.id)
    return <>
        <Accordion sx={{width: "93%"}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>

            <AccordionSummary isSelected={isSelected} sx={{
                background: isSelected ? theme.palette.primary.main : "",
                borderRadius: "5px",
                color: selectedMenu.includes(data.id) ? "#fff" : "text.disabled",
                marginBottom: "5px",
                display: 'flex',
                alignItems: "center",
                gap: "10px"
            }} aria-controls="panel1d-content" id="panel1d-header">
                <span style={{display:!isSelected?"none":"",width:isSelected?"fit-content":"0px",height:isSelected?"fit-content":"0px"}}>
                    {data.whiteIcon}
                </span>
                <span style={{display:isSelected?"none":"",width:!isSelected?"fit-content":"0px",height:!isSelected?"fit-content":"0px"}}>
                    {data.icon}
                </span>
                <Typography sx={{margin: "0px"}} variant={"caption"}>{data.name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={"MyAccordionClass"}>
                {data.child.map((val) => (
                    <MenuButton key={`${val.id}`} subMenu={true} selectedMenu={selectedMenu} val={val} parentId={data.id}
                                navigateUser={navigateUser}/>
                ))}
            </AccordionDetails>

        </Accordion>

    </>
}
export default DropDownMenuComponent