import React from 'react';
import {Box, Dialog} from "@mui/material";
import DialogHeader from "../layout-component/dialog-header";
export function StyledDialogCell({children,sx,...p}){
    return <>
        <Box sx={{padding: "15px", width: "100%", paddingTop: "0px",...sx}} {...p}>
            {children}
        </Box>
    </>
}
function StyledDialog({onClose, open,iconFontSize,titleIcon, title,children,...props}) {
    return (
        <>
            <Dialog open={open}
                    sx={{
                        "& .MuiDialog-paper": {
                            maxWidth: "fit-content"
                        }
                    }}
                    onClose={onClose}
            >
                <Box sx={{
                    display: "table",
                    flexDirection: "column",
                    // width: "fit-content",
                    gap: "5px",
                    overflow: "hidden",
                    width: "100%",

                }}>
                <DialogHeader title={title} icon={titleIcon} iconSize={iconFontSize} onClose={onClose}/>
                </Box>
                <Box sx={{minHeight:"50px",maxHeight:"100%",overflow:"auto"}}>
                {children}
                </Box>

            </Dialog>
        </>
    );
}

export default StyledDialog;