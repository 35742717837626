import React, {useEffect, useRef, useState} from 'react';
import {Button, CircularProgress} from "@mui/material";

function LoadingButton({onClick, loading, label,disabled, ...p}) {
    const [parentSize, setParentSize] = useState(0);
    const parentRef = useRef(null);
    useEffect(() => {
        const {clientHeight, clientWidth} = parentRef.current;

        setParentSize(Math.min(clientHeight, clientWidth));
    }, []);
    return (
        <> <Button ref={parentRef} sx={{textTransform: 'capitalize', width: "100%"}} color={"primary"}
                   variant={"contained"}  disabled={loading || disabled} onClick={onClick} {...p}>{label}
            {loading &&
            <CircularProgress color={"primary"} size={0.6 * parentSize} sx={{marginLeft: "5px", color: "grey.500"}}/>}
        </Button>
        </>
    );
}

export default LoadingButton;