import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {configStore} from "./store";
import {initializeAppState} from "./helper/AppContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

async function renderStart() {
    let context = {}
    try {
        context = await initializeAppState()
    } catch (e) {
    }
    return {context: context}
}


renderStart().then((res) => {
    const {context} = res
    let store = configStore({...context})
    root.render(
        // <React.StrictMode>

        <App store={store}/>
        // </React.StrictMode>
    );
}).catch((error) => {
    console.log(error, "Something Went Wrong")
})
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
