import React from 'react';

function RightArrowIcon(props) {
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.000244141" width="32" height="32" rx="4" fill="#D9E4FF"/>
                <path d="M22.4583 15.7718H9.95825" stroke="#1E5EFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.4167 10.7514L22.4584 15.7714L17.4167 20.7922" stroke="#1E5EFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        </>
    );
}

export default RightArrowIcon;