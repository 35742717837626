import React, {useState} from 'react';
import {FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, SvgIcon, useTheme} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import UserAdd from "../../../../assets/svg/user-add.svg"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
function PasswordInputField(props) {
    const theme=useTheme()
    const [showPassword, setShowPassword] = useState(false)
    const {error,value,onChange,errorMassage="Enter correct password",instruction="",strongPasswordError,name="",id,autoComplete="password",...p}=props
    function handleClickShowPassword() {
        setShowPassword(prevState => (!prevState))
    }

    return (
        <>
            <FormControl variant="outlined" fullWidth={true}>
                <OutlinedInput
                    className={"user-password-field"}
                    placeholder={"Enter Your Password"}
                    id={id || "outlined-adornment-password"}
                    type={showPassword ? "text" : 'password'}
                    error={error}
                    onChange={(e)=>onChange(e)}
                    value={value}
                    defaultValue={""}
                    inputProps={{autoComplete:autoComplete}}
                    name={name}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                            </IconButton>
                        </InputAdornment>
                    }

                    {...p}
                />
                {instruction.length !==0 && <FormHelperText sx={{color:theme.palette.text.disabled}}>{instruction}</FormHelperText>}
                {error &&  < FormHelperText >{errorMassage}< /FormHelperText>}
            </FormControl>
        </>
    );
}

export default PasswordInputField;