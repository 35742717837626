const initialState={
    userUpdateCounter: 0,
    token: null,
    isAuthenticated:false,
    refreshToken: null,
    userName: "",
    role: "A",
    companyId: null,
    users: []
}
export default initialState