import React, {useRef, useState} from 'react';
import {Autocomplete, Popper, TextField} from "@mui/material";

function Selector({selected,display,onChange,compareId,optionData=[],error,placeholder,...p}) {
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null); // Anchor element for the custom Popper
    const ref=useRef(null)
    return (
        <>
                <Autocomplete
                value={selected}
                getOptionLabel={(option) => option[display]}
                onChange={(event, newValue) => {
                    onChange(newValue,event)
                }}
                ref={ref}
                multiple={false}
                options={optionData || []}
                isOptionEqualToValue={(a,b)=>(a[compareId]===b[compareId])}
                size={"small"}
                fullWidth
                noOptionsText={""}
                // disabled={true}
                PopperComponent={(props) => (
                    optionData.length !==0 && <CustomPopper {...props} anchorEl={ref.current} setAnchorEl={setAnchorEl} />
                )}
                renderInput={(params) => <TextField placeholder={placeholder} error={error} size={"small"} {...params}  />}
                {...p}

            />

        </>
    );
}

const CustomPopper = (props) => {
    return (
        <Popper
            {...props}
            placement="bottom"
            sx={{
                // height: "10px",
                // marginTop:"5px"
            }}
            style={{width: props.anchorEl.clientWidth}}
        />
    );
}

export default Selector;