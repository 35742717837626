import React, {useState} from 'react';
import StyledDialog, {StyledDialogCell} from "../../../../components/dialog-component/styled-dialog";
import {Box, Divider, Typography} from "@mui/material";
import LoadingButton from "../../../../components/button-component/loading-button";
import moment from "moment";
import useUser from "../../../../../custom-hook/use-user";
import LicencesApi from "../../../../../apis/licences/licences-api"
import {ErrorToast} from "../../../../../Util/util";

function LicencePayment(props) {
    const {getUserTimeZone,getUserInstallationId,getToken,getCompanyId}=useUser()
    const {open,onClose,afterSave,apiRef,row}=props
    const [state,setState]=useState({loading:{licencePayment:false}})
    const {loading}=state
    function onSubmit(){
        setState(prevState => ({...prevState,loading: {...prevState.loading,licencePayment: true}}))
        let today=moment().tz(getUserTimeZone()).format('YYYY-MM-DD')
        let params={expiryDate:null,paymentDate:today,installationId:getUserInstallationId(),purchaserCompanyId:getCompanyId()}
        LicencesApi.updateLicence(getUserInstallationId(),row.licenceCode,params,getToken()).then((res)=>{
            setState(prevState => ({...prevState,loading: {...prevState.loading,licencePayment: false}}))
            apiRef.current.updateRows([{...row,expiryDate:null,paymentDate:today}])
            afterSave()
        }).catch((e)=>{
            setState(prevState => ({...prevState,loading: {...prevState.loading,licencePayment: false}}))

            ErrorToast(e)
        })
    }


    return (
        <>
            <StyledDialog title={"Mark Licence"} open={open} onClose={() => onClose()}>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <Box sx={{display: "flex", gap: 2, flexDirection: "column", width: "25vw"}}>

                        <Box sx={{display:"flex",flexDirection:"column",gap:0.3}}>
                            <Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Set the payment date as today and mark this licence as paid.  </Typography>

                        </Box>
                    </Box>
                </StyledDialogCell>
                <Divider/>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <LoadingButton loading={loading.licencePayment} color={"primary"}  onClick={() => onSubmit()} label={"Confirm"}/>
                </StyledDialogCell>
            </StyledDialog>

        </>
    );
}

export default LicencePayment;