import {Box, Typography} from "@mui/material";
import React from "react";
import AssignLicenceIcon from "../../../../../assets/icons/licence-icon/assign-licence-icon";
import DisableAssignLicenceIcon from "../../../../../assets/icons/licence-icon/disable-assign-licence-icon";
import DollarErrorIcon from "../../../../../assets/icons/licence-icon/dollar-error-icon";
import DollarPrimaryIcon from "../../../../../assets/icons/licence-icon/dollar-primary-icon";
import UnassignLicenceIcon from "../../../../../assets/icons/licence-icon/unassign-licence-icon";
import DeleteFillidIcon from "../../../../../assets/icons/delete-button-icon";
import moment from "moment";
import {Link} from "react-router-dom";
import {timeFormatter} from "../../../../../helper/helper";
// let todayDateOne=moment("2050-11-01", 'YYYY-MM-DD');
let todayDateOne=moment();
function isExpired(expirationDate) {
    const expireDate = moment(expirationDate, 'YYYY-MM-DD');
    const todayDate = todayDateOne

    if (expireDate.isBefore(todayDate, 'day')) {
        return true // Expired
    } else if (expireDate.isSame(todayDate, 'day')) {
        return "Today"; // It's today
    } else {
        return false; // Not expired
    }
}
// function isDateExpired(expirationDate,timeZone) {
//     let date = new Date(expirationDate || "");
//     // let date = new Date();
//     let isoDate = date.toISOString();
//     const expireDate = moment(isoDate, 'YYYY-MM-DD').tz(timeZone);
//     const todayDate = moment().tz(timeZone);
//
//     if (expireDate.isBefore(todayDate, 'day')) {
//         return "Expired";
//     } else if (expireDate.isSame(todayDate, 'day')) {
//         let time = timeFormatter(expirationDate); // It's today
//         return `Today`
//     } else {
//         let time = timeFormatter(expirationDate); // It's today
//         // return timeFormatter(expirationDate); // Not expired
//         return `${time.year}-${time.month}-${time.day}` // Not expired
//     }
// }
function isDateExpired(expirationDate) {
    const expireDate = moment(expirationDate, 'YYYY-MM-DD');
    const todayDate = todayDateOne

    if (expireDate.isBefore(todayDate, 'day')) {
        return "Expired"
    } else if (expireDate.isSame(todayDate, 'day')) {
        return expirationDate // It's today
    } else {
        return expirationDate; // Not expired
    }
}


function GetLicenceAction(props){
    const {row,onAssignLicence,onUnAssignLicence}=props
    if(row.ocppName){
        return <>{row?.ocppName  && <Box onClick={()=>onUnAssignLicence(row)} sx={{display: "flex", cursor:"pointer", alignItems: "center"}}>
            <span style={{display: "flex"}}><UnassignLicenceIcon fontSize={"24px"}/></span>
        </Box>}</>
    }
    return <>
        {!row.ocppName  && !isExpired(row?.expiryDate) && <Box sx={{display: "flex",cursor:"pointer", alignItems: "center"}}>
            <span style={{display: "flex"}} onClick={()=>onAssignLicence(row)}><AssignLicenceIcon fontSize={"24px"}/></span>
        </Box>}
        {!row?.ocppName && isExpired(row?.expiryDate) && <Box sx={{display: "flex", cursor:"pointer", alignItems: "center"}}>
            <span style={{display: "flex"}}><DisableAssignLicenceIcon fontSize={"25px"}/></span>
        </Box>}


    </>
}



export function LicencesCodeColumn(props) {
    const {row} = props;
    return (
        <><Box sx={{display: "flex", alignItems: "center", gap: "5px"}}><Typography variant={"caption"}>{row?.licenceCode}</Typography></Box></>
    );
}
export function LicencesPurchaserCompanyColumn(props) {
    const { row} = props;
    let color={}
    // if(row?.purchaserCompanyName){
    //     color={color:"primary.main"}
    // }
    return (
        <><Box sx={{display: "flex", alignItems: "center", gap: "5px"}}><Typography variant={"caption"} {...color}  >{row?.purchaserCompanyName?row?.purchaserCompanyName:"-"}</Typography></Box></>
    );
}
export function LicencesPurchaseDateColumn(props) {
    const {row} = props;
    //not reciveg date in iso format
    let date=new Date()
    if(row?.paymentDate ){

        date = new Date(row?.purchaseDate || "");
    }
    // let date = new Date();
    let isoDate = date.toISOString();
    let time = timeFormatter(isoDate)
    return (
        <><Box sx={{display: "flex", alignItems: "center", gap: "5px"}}><Typography
            variant={"caption"}>{row?.purchaseDate ? `${time.year}-${time.month}-${time.day}` : "-"}</Typography></Box></>
    );
}
export function LicencesPaymentDateColumn(props){
    const { row } = props;
    let date=new Date()
    if(row?.paymentDate && row?.paymentDate !=="0000-00-00"){

        date = new Date(row?.paymentDate || "");
    }
    if(row?.paymentDate ==="0000-00-00"){
        return <Box sx={{display:"flex",alignItems:"center",gap:"5px"}}><Typography variant={"caption"}>-</Typography></Box>
    }

    // let date = new Date();
    let isoDate = date.toISOString();
    let time=timeFormatter(isoDate)
    return (
        <><Box sx={{display:"flex",alignItems:"center",gap:"5px"}}><Typography variant={"caption"}>{row?.paymentDate?  `${time?.year}-${time?.month}-${time?.day}`:"-"}</Typography></Box></>
    );
}
export function LicencesExpiryDateColumn(props){
    const { id, field, rowNode,row,timeZone } = props;
    // let date = new Date(row?.paymentDate || "");

    return (
        <><Box sx={{display:"flex",alignItems:"center",gap:"5px"}}><Typography variant={"caption"} >{row?.expiryDate && isDateExpired(row?.expiryDate,timeZone)?isDateExpired(row?.expiryDate,timeZone):"-"}</Typography></Box></>
    );
}
// export function LicencesPurchaseDateColumn(props){
//     const { row } = props;
//
//     return (
//         <><Box sx={{display:"flex",alignItems:"center",gap:"5px"}}><Typography variant={"caption"} >{row?.purchaseDate?row?.purchaseDate:"-"}</Typography></Box></>
//     );
// }
// export function LicencesPaymentDateColumn(props){
//     const { row } = props;
//     return (
//         <><Box sx={{display:"flex",alignItems:"center",gap:"5px"}}><Typography variant={"caption"}>{row?.paymentDate?row?.paymentDate:"-"}</Typography></Box></>
//     );
// }
// export function LicencesExpiryDateColumn(props){
//     const { id, field, rowNode,row } = props;
//     return (
//         <><Box sx={{display:"flex",alignItems:"center",gap:"5px"}}><Typography variant={"caption"} >{row?.expiryDate && isDateExpired(row?.expiryDate)?isDateExpired(row?.expiryDate):"-"}</Typography></Box></>
//     );
// }
export function LicencesChargePointColumn(props){
    const { id, field, rowNode,row } = props;
    let color={}
    if(row?.ocppName){
        color={component :Link,to:"/app/installation/chargepoints",state:{mode:"detail",chargePoint:{chargePointId:row.chargePointId}},sx:{textDecoration:"none"},color:"primary.main"}
    }
    return (
        <><Box sx={{display:"flex",alignItems:"center",gap:"5px"}}><Typography variant={"caption"} {...color} >{row?.ocppName?row?.ocppName:"-"}{row?.parkingSpace?`(${row?.parkingSpace})`:""}</Typography></Box></>
    );
}
export function LicencesActions(props){
    const { id, field, rowNode,row,onAssignLicence, onUnAssignLicence,onDelete,onOpenPayLicenceFee } = props;
    return (
        <>
            <> <Box sx={{display:"flex",alignItems:"center",minWidth:"fit-content"}}>

                {!!row?.paymentDate  && <Box sx={{display: "flex",cursor:"pointer", alignItems: "center"}}>
                    <span style={{display: "flex"}}><DollarErrorIcon fontSize={"24px"}/></span>
                </Box>}
                {!row?.paymentDate  && <Box sx={{display: "flex",cursor:"pointer", alignItems: "center"}}>
                    <span style={{display: "flex"}} onClick={()=>onOpenPayLicenceFee(row)}><DollarPrimaryIcon fontSize={"24px"}/></span>
                </Box>}
                <GetLicenceAction row={row} onAssignLicence={onAssignLicence} onUnAssignLicence={onUnAssignLicence}/>
                {(!row?.chargePointId?.toString() || isExpired(row?.expiryDate))  && <Box sx={{display: "flex",cursor:"pointer", alignItems: "center"}}>
                    <span onClick={()=> {
                        if(!row.isDeleting){
                            onDelete(row)
                        }
                    }} style={{display: "flex"}}><DeleteFillidIcon fontSize={"24px"}/></span>
                </Box>}
            </Box></>
        </>
    );
}


