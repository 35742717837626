import React from 'react';
import {Route, Routes} from "react-router-dom";
import CreateNewAccount from "./create-new-account";
import AccountCreatedSuccess from "./account-created-success";

function Index(props) {

    return (
        <>

                <Routes>
                    {/*<Route path={"/invitation"} element={<GetCodeInput/>}/>*/}
                    {/*<Route path={"/invitation1"} element={<GetCodeInput/>}/>*/}
                    <Route path={"/create-account-success"} element={<AccountCreatedSuccess/>}/>
                    <Route path={"*"} element={<CreateNewAccount />}/>
                </Routes>

        </>
    );
}

export default Index;