import {configureStore} from "@reduxjs/toolkit";
import reducer from "./reducer";


export function configStore(initialState){
    const store=configureStore({
        reducer,
        preloadedState:{
            ...initialState
        }
        // middleware: (getDefaultMiddleware) =>
        //     getDefaultMiddleware({
        //         serializableCheck: false,
        //     })
    });

    return store
}