import React, {useState} from 'react';
import {isEmailValid} from "../../../helper/helper";
import {Avatar, Box, TextField, Typography, useTheme} from "@mui/material";
import KeyIcon from "../../../assets/icons/forgot-password/key-icon.svg";
import LoadingButton from "../../components/button-component/loading-button";
import StyledLinkComponent from "../../../components/styled-link-component";
import CommonRightSidebar from "../components/common-right-sidebar";
import { useNavigate } from "react-router-dom";
function EmailSubmit({formData,...props}) {

    const theme = useTheme()
    const [state, setState] = useState({loading: false, formData: {email: ""}, error: {email: false}})
    let navigate = useNavigate();
    const {error, loading} = state

    function validateForm() {
        let validEmail = isEmailValid(formData.email)
        setState(prevState => ({...prevState, error: {...prevState.error, email: !validEmail}}))
        return (validEmail)
    }

    function onSubmit() {
        // if (validateForm()) {
        navigate('/login')
        // }

    }


    function onFormDataChange(field, value) {
        setState(prevState => ({...prevState, formData: {...prevState.formData, [field]: value}}))
    }

    return (
        <>
            <Box sx={{display: "flex", flexFlow: "row", height: "100vh"}}>
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
                     flex={"1 1"}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "15px",
                        width: "60%"
                    }}
                         flex={"1 1"}>
                        <Box flex={"fit-content 0"} display={"flex"} gap={"5px"} alignItems={"center"}>
                            <Avatar src={KeyIcon} variant="square"/>
                            <Typography variant={"h5"}>Reset password</Typography>
                        </Box>
                        <Box flex={"fit-content 0"} width={"80%"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"} sx={{color: theme.palette.general.general80}}>An email with
                                instructions to reset your password was sent to the <span style={{color:theme.palette.general.general100,fontWeight:"bold"}}>{formData?.email || ""}</span> </Typography>

                        </Box>


                        <Box flex={"fit-content 0"} display={"flex"} gap={"20px"}>
                            <LoadingButton
                                loading={false} onClick={onSubmit} size={"large"}
                                sx={{textTransform: 'capitalize', width: "100%"}} color={"primary"}
                                variant={"contained"} label={"Continue"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} justifyContent={"space-between"} gap={"5px"}>

                            <Box display={"flex"} gap={"4px"}>
                                <StyledLinkComponent path={"/login"}>Back to login</StyledLinkComponent>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <CommonRightSidebar/>
            </Box>
        </>

)
}

export default EmailSubmit;