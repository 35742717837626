import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import HeaderBackButtonIcon from "../../../assets/icons/header-back-button-icon";

function HeaderToolbar({title, children, allowBack = false, onBackClick, ...props}) {
    return (
        <>
            <Box sx={{width: "100%", display: "flex", gap: "10px", paddingY: "10px", alignItems: "center"}}>
                <Box flex={"fit-content 0 "} display={"flex"} gap={0.4} alignItems={"center"}>
                    {allowBack &&
                    <span style={{cursor: "pointer", display: "flex"}} onClick={onBackClick}>
                                <HeaderBackButtonIcon height={"30px"}/>
                            </span>}
                    <Typography sx={{display: "flex", alignItems: "center"}} variant={"h5"}
                                fontWeight={"bold"}>{title}</Typography>
                </Box>
                <Box flex={"1 0 fit-content"} display={"flex"} gap={"10px"} alignItems={"center"} height={"100%"}>
                    {children}
                </Box>
            </Box>
        </>
    );
}

export default HeaderToolbar;