import React from 'react';
import {Switch} from "@mui/material";
import { styled } from '@mui/material/styles';
const AntSwitchLarge = styled(Switch)(({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2.6,
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 15,
        height: 15,
        borderRadius: "20px",
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));
const AntSwitchSmall = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));
function SwitchButton(props) {
    const {size="small"}=props

    function getcheckDesgin(checked,disabled,t) {

        if(checked){
            let border=`1px solid ${t.palette.primary.main} !important`
            if(disabled){
                border=`1px solid #89ABFF !important`
            }
            return {
                "& .MuiSwitch-track": {
                    background:!disabled? "#fff !important":"#D9E4FF !important",
                    border:border
                },
                "& .MuiSwitch-thumb":{
                    background: !disabled?`${t.palette.primary.main} !important`:"#89ABFF !important",
                }
            }
        }else{
            let border=`1px solid #A1A7C4 !important`
            if(disabled){
                border=`1px solid #000000 !important`
            }
            return {
                "& .MuiSwitch-track": {
                    background: !disabled?"#fff !important":"#89ABFF !important",
                    border:border
                },
                "& .MuiSwitch-thumb":{
                    background: `#A1A7C4 !important`,
                }
            }
        }
    }

    return (
        <>
            {size==="small" && <AntSwitchSmall   sx={(t)=>({...getcheckDesgin(props.checked,props.disabled,t)})} {...props} />}
            {size==="large" && <AntSwitchLarge  sx={(t)=>({...getcheckDesgin(props.checked,props.disabled,t)})} {...props} />}
        </>
    );
}

export default SwitchButton;