import axios from "axios";
import {getApiUrl} from "./config";

const qs = require("querystring");

class APIHelper {
    createAPIService(context) {
        return axios.create({
            baseURL: context.base_url,
            timeout: 20000,
            // withCredentials:true
        });
    }

    post(url, data, params, context,mokeApi=false) {
        params = params || {};
        context = context || {};
        this.initContext(context,mokeApi);
        // var queryParams = {};
        this.addAuthParams(params, context);
        var apiService = this.createAPIService(context,mokeApi)
        // var config = {params: params}
        // config.headers = {
        //     'Content-Type': 'application/json;charset=UTF-8'
        //     // 'Content-Type': 'application/json'
        // }
        let configHeader={
            // 'Authorization': context.token,
            // 'Content-Type': 'application/json;charset=UTF-8',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'authorization': context.token
        }
        if( context?.token){
            configHeader={
                ...configHeader,
                'Authorization': context.token,
            }
        }
        return new Promise((resolve, reject) => {
            apiService.post(url, JSON.stringify(data), {headers:{
                ...configHeader
                }}).then(function (response) {
                if (response.errors) {
                    reject(response.errors[0]);
                } else {
                    resolve(response.data);
                }
            }).catch(function (err) {

                if (err.response) {
                    reject(err.response.data);
                } else {
                    reject(err);
                }
            })
        });
    }
    put(url, data, params, context,mokeApi=false) {
        params = params || {};
        context = context || {};
        this.initContext(context,mokeApi);
        this.addAuthParams(params, context);
        var apiService = this.createAPIService(context,mokeApi)

        let configHeader={
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        }

        if( context?.token){
            configHeader={
                ...configHeader,
                'Authorization': context.token,
            }
        }
        return new Promise((resolve, reject) => {
            apiService.put(url, {...data},{headers:{...configHeader}}).then(function (response) {
                if (response.errors) {
                    reject(response.errors[0]);
                } else {
                    resolve(response.data);
                }
            }).catch(function (err) {

                if (err.response) {
                    reject(err.response.data);
                } else {
                    reject(err);
                }
            })
        });
    }
    delete(url, data, params, context,mokeApi=false) {
        params = params || {};
        context = context || {};
        this.initContext(context,mokeApi);
        this.addAuthParams(params, context);
        var apiService = this.createAPIService(context,mokeApi)

        let configHeader={
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        }

        if( context?.token){
            configHeader={
                ...configHeader,
                'Authorization': context.token,
            }
        }
        return new Promise((resolve, reject) => {
            apiService.delete(url, {headers:{...configHeader}}).then(function (response) {
                if (response.errors) {
                    reject(response.errors[0]);
                } else {
                    resolve(response.data);
                }
            }).catch(function (err) {

                if (err.response) {
                    reject(err.response.data);
                } else {
                    reject(err);
                }
            })
        });
    }

    multipartFormPost(url, data, params, context,mokeApi=false) {
        params = params || {};
        context = context || {};
        this.initContext(context,mokeApi);
        this.addAuthParams(params, context);
        var apiService = this.createAPIService(context,mokeApi)

        let configHeader={

            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
        if( context?.token){
            configHeader={
                ...configHeader,
                'Authorization': context.token,
            }
        }
        configHeader['Content-Type']='multipart/form-data'

        return new Promise((resolve, reject) => {
            apiService.post(url, data,{headers:{...configHeader}}).then(function (response) {
                if (response.data.error) {
                    reject(response.data);
                } else {
                    resolve(response.data);
                }
            }).catch(function (err) {
                if (err.response) {
                    reject(err.response.data);
                } else {
                    reject(err);
                }
            })
        });
    }
    multipartFormPut(url, data, params, context,mokeApi=false) {
        params = params || {};
        context = context || {};
        this.initContext(context,mokeApi);
        this.addAuthParams(params, context);
        var apiService = this.createAPIService(context,mokeApi)

        let configHeader={

            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
        if( context?.token){
            configHeader={
                ...configHeader,
                'Authorization': context.token,
            }
        }
        configHeader['Content-Type']='multipart/form-data'

        return new Promise((resolve, reject) => {
            apiService.put(url, data,{headers:{...configHeader}}).then(function (response) {
                if (response.data.error) {
                    reject(response.data);
                } else {
                    resolve(response.data);
                }
            }).catch(function (err) {
                if (err.response) {
                    reject(err.response.data);
                } else {
                    reject(err);
                }
            })
        });
    }

    get(url, params, context,mokeApi=false) {
        params = params || {};
        context = context || {};
        return new Promise((resolve, reject) => {
            this.initContext(context,mokeApi);
            let configHeader={

                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
            }
            if( context?.token){
                configHeader={
                    ...configHeader,
                    'Authorization': context.token,
                }
            }
            var apiService = this.createAPIService(context,mokeApi)
            apiService.get(url, {params: params,headers:configHeader}).then(function (response) {
                if (response.data.error) {
                    reject(response.data);
                } else {
                    resolve(response.data);
                }
            }).catch(function (err) {
                if (err.response) {
                    reject(err.response.data);
                } else {
                    reject(err);
                }
            })
        });
    }


    initContext(context,mokeApi) {
        if(mokeApi){
            context.base_url = "https://codezila.free.beeceptor.com";
        }else{
            // context.base_url = "https://api.varian.com";
            // context.base_url = "http://15.223.68.176:3048";
            context.base_url = getApiUrl();
            // context.base_url = "http://15.223.68.176:8080";
        }
    }

    addAuthParams(params, context) {
        if (context.user_token) {
            params.token = context.user_token;
        }
    }

}

const apiHelperInstance = new APIHelper();
Object.freeze(apiHelperInstance);
export default apiHelperInstance;
