import React from 'react';

function HeaderBackButtonIcon(props) {
    const {height}=props
    return (
        <>
            <svg width="40" height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="4" fill="#D9E4FF"/>
                <path d="M12.25 20.2744L27.25 20.2744" stroke="#1E5EFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.2998 26.2988L12.2498 20.2748L18.2998 14.2498" stroke="#1E5EFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        </>
    );
}

export default HeaderBackButtonIcon;