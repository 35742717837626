import React from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import LogoImage from "../../../assets/svg/Frame 37912.svg";
import SuccessLogin from "../../../assets/svg/payment-successfully.svg";
import LoadingButton from "../../components/button-component/loading-button";

function ForgetPasswordSuccess(props) {
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{display: "flex", flexFlow: "row", minHeight: "100vh",maxHeight:"fit-content"}}>
                <Box flex={"1 1"} sx={{
                    position: "relative",
                    display: "flex",
                    background: "#F6FAFF",
                    // paddingX: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection:"column",
                    gap:"10px"
                }}>
                    <img style={{position: "absolute", width: "130px", left: "15px", top: "15px", zIndex: 12}}
                         src={LogoImage}/>
                    <img src={SuccessLogin}/>
                    <Typography variant={"h5"} sx={theme => ({color:theme.palette.general.general100})}>You successfully changed your password!</Typography>
                    <LoadingButton loading={false} onClick={()=>{navigate("/login")}} sx={{width:"fit-content"}} label={"Back to login"} />
                </Box>
            </Box>
        </>
    );
}

export default ForgetPasswordSuccess;