import React, {useEffect, useMemo, useState} from 'react';
import {Box, Typography, useTheme} from "@mui/material";
import HeaderToolbar from "../../../components/layout-component/header-toolbar";
import ActivityLogIcon from "../../../../assets/icons/activity-log-icon";
import {SimpleButton, TransparentButton} from "../../../components/button-component/custom-button";
import LicencesListFilter from "./components/licences-list-filter";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {
    LicencesActions,
    LicencesChargePointColumn,
    LicencesCodeColumn,
    LicencesExpiryDateColumn,
    LicencesPaymentDateColumn,
    LicencesPurchaseDateColumn,
    LicencesPurchaserCompanyColumn
} from "./components/licences-list-column";
import PurchaseLicences from "./purchase-licences/purchase-licences";
import AssignLicences from "./purchase-licences/assign-licences";
import UnassignLicences from "./purchase-licences/unassign-licences";
import AutoAllocateLicences from "./purchase-licences/auto-allocate-licences";
import ChargePointApi from "../../../../apis/charge-points/charge-points-api";
import LicencesApi from "../../../../apis/licences/licences-api";
import DataGridFrame from "../../../components/layout-component/data-grid-frame";
import DeleteLicencesDialog from "./purchase-licences/delete-licences-dialog";
import useUser from "../../../../custom-hook/use-user";
import {ErrorToast} from "../../../../Util/util";
import StyledMuiDataGrid from "../../../components/rct-data-grid/styled-mui-data-grid";
import LicencePayment from "./purchase-licences/licence-payment";
import moment from "moment";
import {CUSTOMER, GROUP, VARIABLE_GRID} from "../../../../Util/company-types-constant";
import PermissionHelper from "../../../../Util/permission-helper";
import {CUSTOMER_SUPPORT, VIEWER} from "../../../../Util/user-types-constant";
import {toast} from "react-toastify";


async function fetchChargePointList(installationId,params,token){
    return await ChargePointApi.getChargePointData(installationId,params,token)
}
async function fetchLicencesList(installationId,params,token){
    return await LicencesApi.fetchLicencesList(installationId,params,token)
}
async function onLicencesDelete(id,params,token){
    return await LicencesApi.deleteLicence(id,params,token)
}

function getRemainChargePoint(){

}

function InstallationLicences(props) {
    const theme = useTheme()
    const {getUserInstallationId,getToken,getCompanyId,userCURDPermission,getLoginCompany,getUserTimeZone}=useUser()
    const {blackListPermission}=PermissionHelper()
    const LOGIN_COMPANY=useMemo(()=>(getLoginCompany()),[])
    const USER_HAVE_EDIT_PERMISSION=useMemo(()=>userCURDPermission(),[])
    const USER_HAVE_CURD_PERMISSION=useMemo(()=>(USER_HAVE_EDIT_PERMISSION  && LOGIN_COMPANY.companyType !==CUSTOMER ),[])
    // const USER_HAVE_CURD_PERMISSION=useMemo(()=>(blackListPermission([GROUP],[GROUP,CUSTOMER,V],[VIEWER]) ),[])

    const [state, setState] = useState({
        optionData: {licenceCode: [],chargePoint:[]},
        filter: {licenceCode: "", showUnassigned: false, showUnpaid: false, showExpired: false},
        dialog: {purchaseDialog: false,assignLicence:false,unassignLicence:false,autoAllocateLicences:false,deleteLicence:false,licencesPayment:false},
        selectedRow:null,
        loading:{gridLoading:true,syncTopologyLoading:false},
        counts:{unpaidCount:0,unassignCount:0,expiredCount:0},
        licencesTotal:0
    })

    function onFilterChange(field, value) {
        setState(prevState => ({...prevState, filter: {...prevState.filter, [field]: value}}))
    }

    const {dialog, filter, optionData,selectedRow,counts,loading,licencesTotal} = state
    let [rows, setRows] = useState([])
    function getColumnList(){
        let columns=[
            {
                field: 'licenceCode', headerName: 'Licence code', renderCell: (props) => {
                    return <><LicencesCodeColumn {...props} /></>
                },
                flex: 1
            },
            {
                field: 'purchaserCompanyName', headerName: 'Purchaser company',
                flex: 1, type: 'string', renderCell: (props) => {
                    return <><LicencesPurchaserCompanyColumn {...props} /></>
                }
            },
            {
                field: 'purchaseDate', headerName: 'Purchase date', type: 'string',
                width: 150, renderCell: (props) => {
                    return <LicencesPurchaseDateColumn {...props}/>
                }
            },
            {
                field: 'paymentDate', headerName: 'Payment date', type: 'string',
                width: 150, renderCell: (props) => {
                    return <LicencesPaymentDateColumn {...props} />
                }
            },
            {
                field: 'expiryDate', headerName: 'Expiry date', type: 'string',
                width: 150, renderCell: (props) => {
                    return <LicencesExpiryDateColumn timeZone={getUserTimeZone()} {...props} />
                }
            },
            {
                field: 'ocppName', headerName: 'Chargepoint', type: 'string',
                width: 110,flex:0.35, renderCell: (props) => {
                    return <LicencesChargePointColumn {...props} />
                }
            },
            {
                field: 'actions', headerName: 'Actions', type: 'string',
                width: 110, renderCell: (props) => {
                    return <LicencesActions onDelete={onDeleteRow} onOpenPayLicenceFee={onOpenPayLicenceFee} onAssignLicence={onAssignLicence} onUnAssignLicence={onUnAssignLicence} {...props} />
                }
            },

        ]
        let visableList=[...columns]
        if(!USER_HAVE_CURD_PERMISSION){
            visableList.splice(6,1)
        }
        return visableList
    }
    const [column, setColumn] = useState(getColumnList())
    const [paginationModel, setPaginationModel] = useState({pageSize: 10, page: 0})
    const [isControllerDirty,setControllerDirty]=useState(false)

    function onPaginationChange(props) {
        setPaginationModel(props)
    }
    //for purchase
    function onPurchase() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, purchaseDialog: true}}))
    }
    function onPurchaseCancel() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, purchaseDialog: false}}))
    }
    function afterSavePurchase() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, purchaseDialog: false}}))
        fetchLicences()
    }
    //for assign licence
    function onAssignLicence(row) {
        setState(prevState => ({...prevState, selectedRow: {...row},dialog: {...prevState.dialog, assignLicence: true}}))
    }
    function onAssignLicenceCancel() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, assignLicence: false}}))
    }
    function afterAssignLicence(row) {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, assignLicence: false}}))
        fetchLicences()
        fetchChargePoint()
        // apiRef.current.updateRows([{ id: row.id,licenceAssigned:true }]);
    }
    const apiRef = useGridApiRef();
    //for unassign licence
    function onUnAssignLicence(row) {
        setState(prevState => ({...prevState, selectedRow: {...row}, dialog: {...prevState.dialog, unassignLicence: true}}))
    }
    function onUnAssignLicenceCancel() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, unassignLicence: false}}))
    }
    function afterUnAssignLicence(row) {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, unassignLicence: false}}))
        // apiRef.current.updateRows([{ id: row.id,licenceAssigned:false }]);
        fetchLicences()
        fetchChargePoint()
    }
    //for unassign licence
    function onAutoAllocateLicence() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, autoAllocateLicences: true}}))
    }
    function onAutoAllocateLicenceCancel() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, autoAllocateLicences: false}}))
    }
    function afterAutoAllocateLicence() {
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, autoAllocateLicences: false}}))
        fetchLicences()
        fetchChargePoint()
    }

    function fetchChargePoint(){
        //
        let params={}
        params.companyId=getCompanyId()
        params.filter='unassigned'
        // params.licenceCode=false
        fetchChargePointList(getUserInstallationId(),{...params},getToken()).then((res)=>{
            let rowsList=res.data?.chargePoints || []
            setState(prevState => ({...prevState,optionData: {...prevState.optionData,chargePoint: [...rowsList]}}))
        }).catch((e)=>{
            ErrorToast(e)
            // toast.error("Something Went Wrong")
            // setState(prevState => ({...prevState,loading: {...prevState.loading,gridLoading: false}}))
        })
    }
    function fetchLicences(){
        setState(prevState => ({...prevState,loading: {...prevState.loading,gridLoading: true}}))
        let params={}


        let filterList=[]
        if(filter.showExpired){
            filterList.push("expired")
        }
        if(filter.showUnassigned){
            filterList.push("unassigned")
        }
        if(filter.showUnpaid){
            filterList.push("unpaid")
        }

        if(filterList.length !==0){
            params.filter=filterList.join(",")
        }

        params.companyId=getCompanyId()
        let unpaidCount=0; let unassignCount=0; let expiredCount=0;
        if(filter.licenceCode.trim() !==""){
            params.searchKey=filter.licenceCode
        }
        fetchLicencesList(getUserInstallationId(),{...params},getToken()).then((res)=>{
            unpaidCount=res.data?.unpaidCount  || 0
            expiredCount=res.data?.expiredCount || 0
            unassignCount=res.data?.unassignedCount || 0
            let licencesList=res.data?.licences || []
            setControllerDirty(res?.data?.controllerDirty || false)

            let licencesTotal=res.data?.totalCount || 0
            licencesList.map((val,index,arr)=>{
                let lId=val.id
                arr[index]={...val,id:index,licenceId:lId,isDeleting:false}
            })

            setState(prevState => ({...prevState,licencesTotal: licencesTotal,loading: {...prevState.loading,gridLoading: false},counts: {...prevState.counts,unpaidCount,expiredCount,unassignCount}}))
            setRows(licencesList)
        }).catch((e)=>{
            ErrorToast(e)
            // toast.error("Something Went Wrong")
            setState(prevState => ({...prevState,loading: {...prevState.loading,gridLoading: false}}))
        })
    }
    function onDeleteRow(row){
        setState(prevState => ({...prevState, selectedRow: {...row}, dialog: {...prevState.dialog, deleteLicence: true}}))
    }
    function onCancelDeleteRow(){
        setState(prevState => ({...prevState, selectedRow: null, dialog: {...prevState.dialog, deleteLicence: false}}))
    }
    function afterDeletedRow(row){
        // apiRef.current.updateRows([{ id: row.id,isDeleting:true }]);
        // onLicencesDelete(row.licenceCode,{},getToken()).then((res)=>{
        //     toast.success("Delete Successfully")
            apiRef.current.updateRows([{ id: row.id, _action: "delete" }]);
        setState(prevState => ({...prevState, selectedRow: null,licencesTotal: prevState.licencesTotal - 1 , dialog: {...prevState.dialog, deleteLicence: false}}))
        //     setState(prevState => ({...prevState,licencesTotal: prevState.licencesTotal-1}))
        //     if(row?.chargePointId){
        //         fetchChargePoint()
        //     }
        // }).catch((e)=>{
        //     toast.error("Something Went Wrong")
        // })

    }
    useEffect(()=>{
        fetchChargePoint()
    },[])
    useEffect(()=>{
        fetchLicences()
    },[paginationModel,filter])
    function onSearch(query){
        setState(prevState => ({...prevState,filter: {...prevState.filter,licenceCode: query}}))
    }
    function onOpenPayLicenceFee(row){
        setState(prevState => ({...prevState,selectedRow: row, dialog: {...prevState.dialog, licencesPayment: true}}))
    }
    function onClosePayLicenceFee(){
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, licencesPayment: false}}))
    }
    function afterPaymentDone(){
        setState(prevState => ({...prevState, dialog: {...prevState.dialog, licencesPayment: false}}))

    }


    const FilterComponent = useMemo(() => {
        const props = {filter, onSearch, optionData,counts, onFilterChange}
        return <LicencesListFilter {...props}  />
    }, [filter, optionData,counts])
    const unAssignedChargepointLength=useMemo(()=>optionData.chargePoint.length,[optionData.chargePoint])
    function syncTopology(){
        setState(prevState => ({...prevState,loading: {...prevState.loading,syncTopologyLoading: true}}))
        LicencesApi.syncTopology(getUserInstallationId(),{},getToken()).then((res)=>{
            toast.success("Topology synced successfully.")
            setState(prevState => ({...prevState,loading: {...prevState.loading,syncTopologyLoading: false}}))
            setControllerDirty(false)
        }).catch((e)=>{
            setState(prevState => ({...prevState,loading: {...prevState.loading,syncTopologyLoading: false}}))
            ErrorToast(e)
        })
    }

    return (
        <>
            <LicencePayment apiRef={apiRef} row={selectedRow} open={dialog.licencesPayment} onClose={onClosePayLicenceFee} afterSave={afterPaymentDone} />
            <AssignLicences optionData={optionData} row={selectedRow} open={dialog.assignLicence} onClose={onAssignLicenceCancel} afterSave={afterAssignLicence}/>
            <DeleteLicencesDialog row={selectedRow} open={dialog.deleteLicence} onClose={onCancelDeleteRow} afterDelete={afterDeletedRow}/>
            <UnassignLicences row={selectedRow} open={dialog.unassignLicence} onClose={onUnAssignLicenceCancel} afterSave={afterUnAssignLicence}/>
            <AutoAllocateLicences unAssinedChargepoint={unAssignedChargepointLength} unallocatedLicenceCount={counts?.unassignCount || 0} open={dialog.autoAllocateLicences} onClose={onAutoAllocateLicenceCancel} afterSave={afterAutoAllocateLicence}/>
            <PurchaseLicences afterSave={afterSavePurchase} open={dialog.purchaseDialog} onClose={onPurchaseCancel} />
            <Box sx={{display: "flex", height: "100%", width: "100%", padding: "15px", flexDirection: "column"}}>
                <Box sx={{width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "10px"}}>
                    {/*Header*/}
                    <HeaderToolbar title={<>Licences <Typography sx={{fontSize:"inherit",marginLeft:1}} fontWeight={"normal"} color={"text.disabled"}>({licencesTotal})</Typography></>}>
                        <Box sx={{height: "100%", width: "100%", display: "flex", justifyContent: "space-between"}}>
                            <Box>

                            </Box>
                            <Box sx={{display: "flex", gap: "5px", flex: "fit-content 0", alignItems: "center"}}>
                                <ActivityLogIcon fontSize={"30px"}/>
                                {USER_HAVE_CURD_PERMISSION && <><TransparentButton onClick={onAutoAllocateLicence}>Assign unallocated
                                    licences</TransparentButton>
                                    <SimpleButton variant={"contained"} onClick={onPurchase}>Purchase
                                        licences</SimpleButton> <SimpleButton variant={"contained"} disabled={!isControllerDirty || loading.syncTopologyLoading} onClick={syncTopology}>Sync
                                        Topology</SimpleButton></>}
                                </Box>
                        </Box>
                    </HeaderToolbar>
                    {FilterComponent}

                    <>
                        <StyledMuiDataGrid
                            // rows={records}
                            rows={rows}
                            columns={column}
                            loading={loading.gridLoading}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pagination={true}
                            apiRef={apiRef}

                            sx={{
                                // height: 'inherit !important', width: "inherit",
                                maxHeight: "100%",
                                background: theme.palette.background.default,
                                "& .MuiSelect-select": {
                                    // display:"block",
                                    paddingRight: "24px !important",
                                },

                            }}
                            disableRowSelectionOnClick={true}
                            // experimentalFeatures={{ariaV7:true}}
                            pageSizeOptions={[10,25,50,75,100]}
                            autoHeight={false}
                        />
                    </>

                </Box>
            </Box>

        </>
    );
}

export default InstallationLicences;