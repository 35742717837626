export const getApiUrl=()=>{
    const DEVELOPMENT_CONFIG = {

        base_url: 'http://15.223.68.176:3048',
    }

    const PRODUCTION_CONFIG = {
        base_url: window.location.origin
    }
        return process.env.NODE_ENV === 'development' ? DEVELOPMENT_CONFIG.base_url : PRODUCTION_CONFIG.base_url
}

// export const getApiUrl=()=>{
//     // export const isProdMode = (): boolean => {
//     if (process.env.NODE_ENV === 'development') {
//         return 'http://15.223.68.176:3048';
//     }
//     return 'http://15.223.1.41:3048';
//     // }
// }
// export default (process.env.NODE_ENV === 'development' ? DEVELOPMENT_CONFIG : PRODUCTION_CONFIG);