import React, {useEffect, useState} from 'react';
import StyledDialog, {StyledDialogCell} from "../../../../components/dialog-component/styled-dialog";
import {Box, Divider, FormHelperText, Typography, useTheme} from "@mui/material";
import Selector from "../../../../components/selectors-component/selector";
import LoadingButton from "../../../../components/button-component/loading-button";
import LicencesApi from "../../../../../apis/licences/licences-api"
import {useSelector} from "react-redux";
import {getActiveUser} from "../../../../../helper/helper";
import useUser from "../../../../../custom-hook/use-user";
import {ErrorToast} from "../../../../../Util/util";

function generateArray(length) {
    const result = [];
    for (let i = 1; i <= length; i++) {
        result.push({ name: i.toString(), id: i });
    }
    return result;
}

// Generate an array of objects with the desired structure
const AmountList = generateArray(15);

function PurchaseLicences(props) {
    const theme = useTheme()
    const {afterSave,open,onClose}=props
    const [state, setState] = useState({
        optionData: {licenseAmount: [...AmountList]},
        formData: {licenseAmount: null},
        loading:{saveLoading:false}
    })
    const {formData, optionData,loading} = state

    function onFormDataChange(field, value) {
        setState(prevState => ({...prevState,formData: {...prevState.formData,[field]:value}}))
    }
    const {getToken,getUserInstallationId,getCompanyId,getLoginCompany}=useUser()
    function onSubmit(){
        if(formData?.licenseAmount===null){
           return
        }
        setState(prevState =>({...prevState,loading: {...prevState.loading,saveLoading: true}}) )
        let params={}
        params.purchaserCompanyId=getLoginCompany()?.companyId || null
        params.count=formData.licenseAmount.id
        LicencesApi.purchaseLicences(getUserInstallationId(),{...params},getToken()).then((res)=>{
                setState(prevState =>({...prevState,loading: {...prevState.loading,saveLoading: false}}) )
                afterSave()
        }).catch((e)=>{
            setState(prevState =>({...prevState,loading: {...prevState.loading,saveLoading: false}}) )
            ErrorToast(e)
        })

    }
    useEffect(()=>{
        setState(prevState => ({...prevState,formData: {...prevState.formData,licenseAmount: null}}))
    },[open])

    return (
        <>
            <StyledDialog title={"Purchase licences"} open={open} onClose={() => onClose()}>
                <StyledDialogCell sx={{minWidth:"35vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <Box flex={"1 1"} display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography flex={"1"}   variant={"caption"}>Number of licences to
                            purchase <sup
                                style={{color: theme.palette.error.main}}>*</sup> </Typography>
                        <Box sx={{flex: "1"}}>
                            <Selector placeholder={"Select amount of licences"} disableClearable={true} display={"name"}
                                      compareId={"id"} selected={formData.licenseAmount}
                                      optionData={optionData.licenseAmount}
                                      onChange={(e) => onFormDataChange("licenseAmount", e)}/>
                        </Box>
                    </Box>
                </StyledDialogCell>
                <Divider/>
                <StyledDialogCell sx={{marginTop:"15px"}}>
                        <LoadingButton loading={loading.saveLoading} color={"primary"} onClick={() => onSubmit()} label={"Purchase"}/>
                </StyledDialogCell>
            </StyledDialog>
        </>
    );
}

export default PurchaseLicences;