import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getActiveUser} from "../../../../../helper/helper";
import ChargePointApi from "../../../../../apis/charge-points/charge-points-api";
import {toast} from "react-toastify";
import StyledDialog, {StyledDialogCell} from "../../../../components/dialog-component/styled-dialog";
import {Box, Divider, Stack, Typography} from "@mui/material";
import Selector from "../../../../components/selectors-component/selector";
import LoadingButton from "../../../../components/button-component/loading-button";
import AboutYellowIcon from "../../../../../assets/icons/about-yellow-icon";
import LicencesApi from "../../../../../apis/licences/licences-api";
import {ErrorToast} from "../../../../../Util/util";
import useUser from "../../../../../custom-hook/use-user";
async function onLicencesDelete(installationId,id,params,token){
    return await LicencesApi.deleteLicence(installationId,id,params,token)
}
function DeleteLicencesDialog(props) {
    const {open,onClose,afterDelete,row}=props
    const {getToken,getUserInstallationId}=useUser()
    const [state,setState]=useState({loading:{deleteLoading:false}})
    const {loading}=state
    function onSubmit(){
        setState(prevState => ({...prevState,loading: {...prevState.loading,deleteLoading: true}}))
        onLicencesDelete(getUserInstallationId(),row.licenceCode,{},getToken()).then((res)=>{
            toast.success("Licence Delete Successfully")
            setState(prevState => ({...prevState,loading: {...prevState.loading,deleteLoading: false}}))
            afterDelete(row)
        }).catch((e)=>{
            ErrorToast(e)
            // toast.error("Something Went Wrong")
            setState(prevState => ({...prevState,loading: {...prevState.loading,deleteLoading: false}}))
        })


    }
    useEffect(()=>{
        setState(prevState => ({...prevState,loading: {...prevState.loading,deleteLoading: false}}))
    },[open])

    return (
        <>
            <StyledDialog title={<Stack alignItems={"center"} flexDirection={"row"} gap={0.3} > <AboutYellowIcon fontSize={"24px"}/>Delete licence</Stack>} open={open} onClose={() => onClose()}>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <Box sx={{display: "flex", gap: 2, flexDirection: "column", width: "25vw"}}>
                        <Box sx={{display:"flex",flexDirection:"column",gap:0.3}}>
                            <Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Are You Sure To Delete Licence <Typography variant={"subtitle2"} fontWeight={"bold"} color={"text.primay"}>{row?.licenceCode}</Typography></Typography>
                            {/*<Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Licence: <Typography variant={"subtitle2"} fontWeight={"bold"} color={"text.primay"}>{row?.licenceCode}</Typography></Typography>*/}
                        </Box>
                    </Box>
                </StyledDialogCell>
                <Divider/>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <LoadingButton loading={loading.deleteLoading} color={"error"}  onClick={() => onSubmit()} label={"Delete licence"}/>
                </StyledDialogCell>
            </StyledDialog>

        </>
    );
}

export default DeleteLicencesDialog;