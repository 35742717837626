import ApiHelper from "../../helper/ApiHelper";

class InvitationApis {

    verifyInvitation(params, token) {
        return ApiHelper.get("/admin/api/v1/invitation", {...params}, {token: token},true);
    }
    loginApi(params, token) {
        return ApiHelper.post("/admin/api/v1/login", {...params}, {},{token: token},false);
    }
    getAccessToken(params, token) {
        return ApiHelper.post("/admin/api/v1/login/refresh-token", {...params}, {},{token: token},false);
    }

}

const serviceInstance = new InvitationApis();
Object.freeze(serviceInstance);
export default serviceInstance;