import React from 'react';

function AboutYellowIcon(props) {
    const {fontSize}=props
    return (
        <>
            <svg width="20" height={fontSize} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0003 1.66602C5.40866 1.66602 1.66699 5.40768 1.66699 9.99935C1.66699 14.591 5.40866 18.3327 10.0003 18.3327C14.592 18.3327 18.3337 14.591 18.3337 9.99935C18.3337 5.40768 14.592 1.66602 10.0003 1.66602ZM9.37533 6.66602C9.37533 6.32435 9.65866 6.04102 10.0003 6.04102C10.342 6.04102 10.6253 6.32435 10.6253 6.66602V10.8327C10.6253 11.1743 10.342 11.4577 10.0003 11.4577C9.65866 11.4577 9.37533 11.1743 9.37533 10.8327V6.66602ZM10.767 13.6493C10.7253 13.7577 10.667 13.841 10.592 13.9243C10.5087 13.9993 10.417 14.0577 10.317 14.0993C10.217 14.141 10.1087 14.166 10.0003 14.166C9.89199 14.166 9.78366 14.141 9.68366 14.0993C9.58366 14.0577 9.49199 13.9993 9.40866 13.9243C9.33366 13.841 9.27533 13.7577 9.23366 13.6493C9.19199 13.5493 9.16699 13.441 9.16699 13.3327C9.16699 13.2243 9.19199 13.116 9.23366 13.016C9.27533 12.916 9.33366 12.8243 9.40866 12.741C9.49199 12.666 9.58366 12.6077 9.68366 12.566C9.88366 12.4827 10.117 12.4827 10.317 12.566C10.417 12.6077 10.5087 12.666 10.592 12.741C10.667 12.8243 10.7253 12.916 10.767 13.016C10.8087 13.116 10.8337 13.2243 10.8337 13.3327C10.8337 13.441 10.8087 13.5493 10.767 13.6493Z" fill="#F99600"/>
            </svg>

        </>
    );
}

export default AboutYellowIcon;