import React from 'react';
import {Route, Routes} from "react-router-dom";
import AccountCreatedSuccess from "../registration/account-created-success";
import CreateNewAccount from "../registration/create-new-account";
import ForgotPassword from "./forgot-password";
import ForgotPasswordLinkSubmit from "./forgot-password-link-submit";
import AddNewPassword from "./add-new-password";

function Index(props) {
    return (
        <>
            <Routes>
                {/*<Route path={"/invitation"} element={<GetCodeInput/>}/>*/}
                {/*<Route path={"/invitation1"} element={<GetCodeInput/>}/>*/}
                <Route path={"/new-password"} element={<><AddNewPassword /></>}/>
                {/*<Route path={"/:any"} element={<><ForgotPasswordLinkSubmit/></>}/>*/}
                {/*<Route path={"/create-account-success"} element={</>}/>*/}
                <Route path={"*"} element={<ForgotPassword />}/>
            </Routes>
        </>
    );
}

export default Index;