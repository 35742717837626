import React from 'react';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useTheme} from "@mui/material";
import DataGridFrame from "../layout-component/data-grid-frame";
import {withWidth} from "../../../helper/layout-helper";
//10 rows height 630.800px
function BigScreenHeight(width, xxlHeight) {
    let maxHeight = "632px"
    if (xxlHeight) {
        maxHeight = xxlHeight
    }
    if (width === "xxl") {
        return {
            minHeight: "632.8px !important",
            height: "632.8px !important",
            return: {
                minHeight: `${maxHeight} !important`,
                height: `${maxHeight} !important`
                // height: "fit-content",
                // maxHeight: "100% !important",
            }
        }
        return {}
    }
}
function StyledMuiDataGrid({sx,xxlHeight,...props}) {
    const theme = useTheme()
    return (
        <>
            <DataGridFrame>

                <DataGridPro
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}

                    componentsProps={{
                        pagination: {
                            SelectProps: {
                                MenuProps: {
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                fontSize: '0.75rem', // Customize font size for menu items
                                            },
                                        },
                                    },
                                },
                            },
                            sx: {
                                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-selectIcon': {
                                    fontSize: '0.75rem', // Customize font size here
                                },
                                '& .MuiTablePagination-menuItem': {
                                    fontSize: '5rem !important', // Customize the menu item font size
                                }
                            },
                        },
                    }}
                    sx={(t)=>( {
                        ...sx,
                        '& .MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within': {
                            outline: 'none !important'
                        },
                        // '& .MuiDataGrid-virtualScroller + div': {
                        //     color: 'transparent !important'
                        // },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none !important'
                        },
                        ...BigScreenHeight(props.width),
                        maxHeight: "100%",
                        // background: theme.palette.background.default,
                        // background: "red",
                        "& .MuiSelect-select": {
                            // display:"block",
                            paddingRight: "24px !important",
                        },
                        ...BigScreenHeight(props.width, xxlHeight),
                        background: theme.palette.background.default,
                        // background: "red",
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontSize: "0.75rem !important"
                        },
                        "& .MuiTablePagination-selectLabel, & .MuiTablePagination-select,& .MuiTablePagination-displayedRows,& .MuiTablePagination-menuItem": {
                            fontSize: "0.75rem !important"
                        },
                    })}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableMultipleColumnsSorting={true}
                    {...props}

                />
            </DataGridFrame>

        </>
    );
}

export default withWidth()( StyledMuiDataGrid);