import React from 'react';
import {Typography} from "@mui/material";

function FilterHeaderTitle({title,count=0}) {
    return (
        <>
            <Typography variant={"caption"} color={"text.disabled"}>{title} {count?.toString() ?
                <Typography variant={"caption"} color={"text.disabled"}
                            fontWeight={"bold"}>({count})</Typography> : null} </Typography>
        </>
    );
}

export default FilterHeaderTitle;