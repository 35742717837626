import {createSlice} from '@reduxjs/toolkit'
import {removeUserToCache, setUserOnCache} from "../helper/cache-helper";
import INITIAL_STATE_USER_SLICE from "./state.userSlice"
import {getApiUrl} from "../helper/config";
const userSlice = createSlice({
    name: 'user_slice',
    initialState: {
        ...INITIAL_STATE_USER_SLICE
    },
    reducers: {
        //for login user
        loginUser: (state, action) => {
            let userData=action.payload.user
            if(userData?.avatarUrl){
                userData.profileImage=getApiUrl()+userData.avatarUrl
            }
            return {
                ...state, ...userData,
                // companyId:action.payload.companyId?.toString() || "",
                token: action.payload.token,
                isAuthenticated:true,
                refreshToken: action.payload.refreshToken,
                users: [{...action.payload.user,driverSync:false,topologySync:false,controllerSync:false,companyId:action.payload.user.companyId?.toString() || "",userInstallation:{}}]
            }

        },
        //for logout user
        logoutUser: (state, action) => {
            removeUserToCache()
            return {
                userUpdateCounter: 0,
                token: null,
                isAuthenticated:false,
                userName: "",
                role: "A",
                companyId: null,
                refreshToken: null,
                users: []
            }
        },
        //for update token
        updateToken: (state, action) => {
            return {...state, token: action.payload.token}
        },
        //when user switch
        userSwitch: (state, action) => {
            let users = action.payload[0]
            users.forEach((val,index,arr)=>{

                arr[index].userInstallation={}
                arr[index].driverSync=val?.driverSync?val?.driverSync:false
                arr[index].topologySync=val?.topologySync?val?.topologySync:false
                arr[index].controllerSync=val?.controllerSync?val?.controllerSync:false
            })
            let path = action.payload[1]?.path || ""
            let navigate = action.payload[1]?.navigate || false
            if (navigate) {
                //add delay bcz redux update asynchronous
                setTimeout(() => {
                    navigate(path)
                }, 50)
            }
            return {...state, userUpdateCounter: state.userUpdateCounter + 1, users: [...users, ...state.users]}
        },
        //when user go back
        switchParentUser: function (state, action)  {
            // let mainUser=[...state.users.filter((val,index)=>(index!==0))]
            let mainUser=[...state.users]
            mainUser.splice(0,1)
            return {...state, users: [...mainUser]}
        },
        //when update first time first user
        updateFirstUser: (state, action) => {
            // state.users[0]= {...action.payload}
            if(action.payload.companyId===state.users[0].companyId){
                let allUser=[...state.users]
                allUser[0]= {...allUser[0],...action.payload}

                let updateState={...state, userUpdateCounter: state.userUpdateCounter + 1,users:[...allUser]}
                    setUserOnCache(JSON.stringify(updateState))
                return {...state, userUpdateCounter: state.userUpdateCounter + 1,users:[...allUser]}
            }

        },
        //when user add new installation
        activeInstallation: (state, action) => {
            let path = action.payload?.path || ""
            let navigate = action.payload?.navigate || false
            if (navigate) {
                setTimeout(() => {
                    navigate(path)
                }, 50)
            }
            const userList = [...state.users]
            userList[0].installtionExist = true
            userList[0].driverSync = false
            userList[0].topologySync = false
            userList[0].controllerSync = false
            let obj={
                installationCode: action.payload.installation?.installationCode,
                installationId: action.payload.installation?.id,
                    ...action.payload.installation
            }
            userList[0]= {...userList[0],installation:{...obj}}
            state.userUpdateCounter = state.userUpdateCounter + 1
            state.users = [...userList]
        },
        //when installation removed
        removeInstallation: (state, action) => {
            let path = action.payload?.path || ""
            let navigate = action.payload?.navigate || false
            if (navigate) {
                setTimeout(() => {
                    navigate(path)
                }, 50)
            }
            const userList = [...state.users]
            userList[0].installtionExist = false
            state.userUpdateCounter = state.userUpdateCounter + 1
            state.users = [...userList]
        },
        //when user installation info update
        updateUserInstallationInfo:(state,action)=>{
            state.users[0].installation= {...state.users[0].installation,...action.payload.installation}
        },
        updateActiveUser: (state, action) => {

                let allUser=[...state.users]
                allUser[0]= {...allUser[0],...action.payload}
                let updateState={...state, userUpdateCounter: state.userUpdateCounter + 1,users:[...allUser]}
                if(allUser.length===1){
                    setUserOnCache(JSON.stringify(updateState))
                }
                return {...state, userUpdateCounter: state.userUpdateCounter + 1,users:[...allUser]}
        },
        updateLoginMember:(state,action)=>{
            return {...state,...action.payload}
        },
        updateUserLogo:(state,action)=>{
            return {
                ...state, profileImage:action.payload.profileImage,userUpdateCounter: state.userUpdateCounter + 1
            }
        },

    },
})
export const {
    loginUser,
    logoutUser,
    updateToken,
    userSwitch,
    switchParentUser,
    updateFirstUser,
    activeInstallation,
    removeInstallation,
    updateActiveUser,
    updateUserInstallationInfo,
    updateLoginMember,
    updateUserLogo
} = userSlice.actions
export default userSlice.reducer