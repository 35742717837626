import './App.css';
import MainApp from "./MainApp"
import theme from "./Theme"
import {ThemeProvider} from "@mui/material/styles"
import {Provider} from "react-redux";
import {BrowserRouter, Routes, Route} from "react-router-dom";

function App({store}) {

    return (
        <>

            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="*" element={<MainApp/>} />
                        </Routes>
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>

        </>
    );
}

export default App;
