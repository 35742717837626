import React from 'react';
import {Box, Typography} from "@mui/material";
import LogoImage from "../../../assets/svg/Frame 37912.svg";
import SuccessLogin from "../../../assets/svg/payment-successfully.svg";
import LoadingButton from "../../components/button-component/loading-button";
import {useNavigate} from "react-router-dom";
function AccountCreatedSuccess(props) {
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{display: "flex", flexFlow: "row", minHeight: "100vh",maxHeight:"fit-content"}}>
            <Box flex={"1 1"} sx={{
                position: "relative",
                display: "flex",
                background: "#F6FAFF",
                // paddingX: "5px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection:"column",
                gap:"10px"
            }}>
                <img style={{position: "absolute", width: "130px", left: "15px", top: "15px", zIndex: 12}}
                     src={LogoImage}/>
                <img src={SuccessLogin}/>
                <Typography variant={"h5"} sx={{color:"#5A607F"}}>You successfully created an account!</Typography>
                <LoadingButton loading={false} onClick={()=>{navigate("/login")}} sx={{width:"fit-content"}} label={"Continue"} />
            </Box>
            </Box>
        </>
    );
}

export default AccountCreatedSuccess;