import React, {useMemo} from "react"
import {AppBar, Avatar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import HeaderLogo from "../../../../../assets/svg/Varian Wordmark 1.svg";
import DemoImage from "../../../../../assets/png/demo.png";
import { getRole, getSwitchCompany} from "../../../../../helper/helper";
import RightArrowIcon from "../../../../../assets/icons/right-arrow-icon";
import AlertsIcon from "../../../../../assets/svg-icon-for-menu/alerts-icon.svg";
import useUser from "../../../../../custom-hook/use-user";
import {useSelector} from "react-redux";
import {getApiUrl} from "../../../../../helper/config";
import {useNavigate} from "react-router-dom";
const drawerWidth = 250;
function HeaderBox({switchToMainUser,...props}){
    const {getActiveUser,getParentUser}=useUser()
    const navigate = useNavigate()
    const authUser = useSelector(state => state.authUser)
    const ActiveUser = getActiveUser()
    const companyLogo=useMemo(()=>{
        if(authUser.users.length >1){
            let parentUser=getParentUser()
            return getApiUrl()+parentUser?.logoUrl || ""
        }
        return null
    },[authUser])

    function onClickAlert(){
        navigate("/app/alerts")
    }

    return<>
        <AppBar position="fixed"
                sx={{background: "#fff", height: "55px", zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar disableGutters={true}
                     sx={{paddingLeft: "20px !important", minHeight: "55px !important"}}>
                <Box sx={{display: "flex", width: "100%"}}>
                    <Box sx={{flex: `${drawerWidth}px 0`, display: "flex", alignItems: "center"}}>
                        <img src={HeaderLogo} style={{height: "40px"}}/>
                    </Box>
                    <Box sx={{flex: "1 1 fit-content", display: "flex", gap: "10px", alignItems: "center"}}>
                        {!!companyLogo && <Avatar key={authUser?.userUpdateCounter} src={`${companyLogo}`}/>}
                        <Typography variant={"h6"} sx={{
                            background: "#fff",
                            color: (theme) => theme.palette.text.primary
                        }}>{getSwitchCompany(ActiveUser)}</Typography>
                        <IconButton onClick={switchToMainUser}>
                            {/*<SvgIcon>*/}
                            <RightArrowIcon/>
                            {/*</SvgIcon>*/}
                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flex: "fit-content 0 fit-content",
                        height: "100%",
                        paddingX: "10px",
                        gap: "10px",
                        alignItems: "center"
                    }}>
                        <Box sx={{flex: "fit-content"}}>
                            <Avatar src={AlertsIcon} onClick={onClickAlert} sx={{width: "30px", height: "30px",cursor:"pointer"}}></Avatar>
                        </Box>
                        <Box sx={{
                            flex: "fit-content",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px"
                        }}>
                            <Avatar src={authUser?.profileImage} key={authUser?.userUpdateCounter}></Avatar>
                            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <Typography color={"text.disabled"}
                                            variant={"subtitle1"}>{authUser?.firstName} {authUser?.lastName}</Typography>
                                <Typography variant={"subtitle2"}
                                            color={"text.disabled"}>{getRole(authUser?.role).roleName}</Typography>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Toolbar>
        </AppBar>
    </>
}
export default HeaderBox
