import React, {useEffect, useState} from 'react';
import StyledDialog, {StyledDialogCell} from "../../../../components/dialog-component/styled-dialog";
import {Box, Divider, FormHelperText, Typography, useTheme} from "@mui/material";
import Selector from "../../../../components/selectors-component/selector";
import LoadingButton from "../../../../components/button-component/loading-button";
import ChargePointApi from "../../../../../apis/charge-points/charge-points-api"
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {getActiveUser} from "../../../../../helper/helper";
import useUser from "../../../../../custom-hook/use-user";
import {ErrorToast} from "../../../../../Util/util";
function AssignLicences(props) {
    const theme = useTheme()
    const {row ,optionData}=props
    const {afterSave,open,onClose}=props
    const [state, setState] = useState({
        optionData: {chargePoint: []},
        formData: {chargePoint: null},
        loading:{saveLoading:false}
    })
    const {formData,loading} = state

    function onFormDataChange(field, value) {
        setState(prevState => ({...prevState,formData: {...prevState.formData,[field]:value}}))
    }
    const {getCompanyId,getUserInstallationId,getToken}=useUser()
    function onSubmit(){
        if(!formData.chargePoint?.chargePointId?.toString()){
            return
        }
        setState(prevState =>({...prevState,loading: {...prevState.loading,saveLoading: true}}) )
        let params={}
        if(row?.id?.toString()){
            params={licenceId:row.licenceId}
        }
        params.companyId=getCompanyId()
        ChargePointApi.updateChargePoint(getUserInstallationId(),formData.chargePoint.chargePointId,{...params},getToken()).then((res)=>{
            toast.success("Licence Assigned")
            setState(prevState =>({...prevState,loading: {...prevState.loading,saveLoading: false},formData: {...prevState.formData,chargePoint: null}}))
            afterSave(row)
        }).catch((e)=>{
            ErrorToast(e)
            setState(prevState =>({...prevState,loading: {...prevState.loading,saveLoading: false}}))
        })
        // setTimeout(()=>{
        //     setState(prevState =>({...prevState,loading: {...prevState.loading,saveLoading: false},formData: {...prevState.formData,chargePoint: null}}) )
        //     afterSave(row)
        // },1000)
    }
    useEffect(()=>{
        setState(prevState => ({...prevState,formData: {...prevState.formData,chargePoint: null}}))
    },[open])

    return (
        <>
            <StyledDialog title={"Assign licence"} open={open} onClose={() => onClose()}>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5,display:"flex",flexDirection:"column",gap:2}}>
                    <Box flex={"1 1"} display={"flex"} flexDirection={"row"} gap={1}>
                        <Typography    variant={"subtitle2"}>Licence: </Typography>
                        <Typography  fontWeight={"bold"}   variant={"subtitle2"}>{row?.licenceCode}</Typography>
                    </Box>
                    <Box flex={"1 1"} display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography flex={"1"}  color={"text.disabled"} variant={"caption"}>Chargepoint </Typography>
                        <Box sx={{flex: "1"}}>
                            <Selector placeholder={"Select chargepoint"} disableClearable={true} display={"ocppName"}
                                      compareId={"chargePointId"} selected={formData.chargePoint}
                                      optionData={optionData.chargePoint}
                                      onChange={(e) => onFormDataChange("chargePoint", e)}/>
                        </Box>
                    </Box>
                </StyledDialogCell>
                <Divider/>
                <StyledDialogCell sx={{marginTop:"15px"}}>
                    <LoadingButton loading={loading.saveLoading} color={"primary"} onClick={() => onSubmit()} label={"Assign licence"}/>
                </StyledDialogCell>
            </StyledDialog>
        </>
    );
}

export default AssignLicences;