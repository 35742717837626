import React from 'react';
import {Button, useTheme,alpha} from "@mui/material";

function TransparentButton(props) {
    const {sx,children}=props
    const theme=useTheme()
    return (
        <>
            <Button {...props} sx={{...sx,textTransform:"capitalize",background:theme.palette.primary.ultraLight,color:theme.palette.primary.main,padding:"5px",fontSize:"0.8rem",boxShadow:"none",paddingX:"8px","&:hover":{background:theme.palette.primary.ultraLight,boxShadow:"none"}}}>{children}</Button>
        </>
    );
}
function SimpleButton(props){
    const {sx,children}=props
    return (
        <><Button {...props}  sx={{...sx,textTransform:"capitalize",padding:"5px",fontSize:"0.8rem",boxShadow:"none",paddingX:"8px",}}>{children}</Button></>
    )
}
export {TransparentButton,SimpleButton };

