import React from 'react';
import {Box} from "@mui/material";

function FilterWrapper({alignItem="flex-end",...props}) {
    const {children}=props

    return (
        <>
            <Box sx={{height: "fit-content", flex: "1 1", alignItems: alignItem, display: "flex",}}>
                {children}
            </Box>
        </>
    );
}

export default FilterWrapper;