import React from 'react';
import {Box, Typography} from "@mui/material";
import LogoImage from "../../../assets/svg/Frame 37912.svg";
import CodeErrorImage from "../../../assets/svg/payment-unsuccessfull.svg";
import LoadingButton from "../../components/button-component/loading-button";
import {useNavigate} from "react-router-dom";

function ExpireLinkError(props) {
    const navigate = useNavigate()
    return <>
        <Box sx={{display: "flex", flexFlow: "row", minHeight: "100vh",maxHeight:"fit-content"}}>
            <Box flex={"1 1"} sx={{
                position: "relative",
                display: "flex",
                background: "#F6FAFF",
                // paddingX: "5px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection:"column",
                gap:"10px"
            }}>
                <img style={{position: "absolute", width: "130px", left: "15px", top: "15px", zIndex: 12}}
                     src={LogoImage}/>
                <img src={CodeErrorImage}/>
                <Typography variant={"h5"} sx={theme => ({color:theme.palette.general.general100})}>Sorry, but your reset password link was expired</Typography>
                <Typography variant={"h6"} sx={theme => ({color:theme.palette.general.general80})}>Please reset your password again</Typography>
                <LoadingButton loading={false} onClick={()=>{navigate("/forgot-password")}} sx={{width:"fit-content"}} label={"Reset password"} />
            </Box>
        </Box>
    </>
}

export default ExpireLinkError;