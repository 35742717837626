import React from 'react';
import {Box, Typography} from "@mui/material";
import SearchFilterWithOption from "../../../../components/selectors-component/search-filter-with-option";
import SwitchButton from "../../../../components/button-component/switch-button";
import FilterHeaderTitle from "../../../../components/layout-component/filter-header";
import SearchFilter from "../../../../components/inputs-component/search-input";
import FilterFrame from "../../../../components/layout-component/filter-frame";
import FilterWrapper from "../../../../components/layout-component/filter-wrapper";

function LicencesListFilter(props) {
    const {filter,optionData,onSearch,onFilterChange:ofc,counts={unpaidCount:0,unassignCount:0,expiredCount:0}}=props
    function onFilterChange(field, val) {
        if(onFilterChange){
            ofc(field,val)
        }
    }

    return (
        <>
            {/*<Box sx={{width: "100%", display: "flex", flex: "fit-content 0", gap: "10px", paddingY: "10px"}}>*/}
            <FilterFrame>
                <Box sx={{display: "flex", flexDirection: "column", flex: "1 1 ", gap: "5px"}}>
                    <FilterHeaderTitle title={"Search by chargepoint name"} count={null}/>
                    {/*<Typography variant={"caption"} color={"text.disabled"}>Search by chargepoint name</Typography>*/}
                    {/*<SearchFilterWithOption sx={{marginRight: 0}} displayId={"name"} compareId={"id"}*/}
                    {/*                        optionData={optionData.occpName} onChange={(val) => onFilterChange("occpName",val)}*/}
                    {/*                        onSearchChange={()=>{}} selected={filter.occpName}/>*/}
                    <FilterWrapper>
                    <SearchFilter onSearch={onSearch} />
                    </FilterWrapper>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", flex: "1 1 ", gap: "5px"}}>
                    {/*<Typography variant={"caption"} color={"text.disabled"}>Show unassigned ({counts.unassignCount})</Typography>*/}
                    <FilterHeaderTitle title={"Show unassigned"} count={counts.unassignCount}/>
                    <FilterWrapper alignItem={"center"}>
                        <SwitchButton checked={filter.showUnassigned}
                                      onChange={(e, val) => onFilterChange("showUnassigned",val)}
                                      size={"large"}/>
                    </FilterWrapper>

                </Box>
                <Box sx={{display: "flex", flexDirection: "column", flex: "1 1 ", gap: "5px"}}>
                    {/*<Typography variant={"caption"} color={"text.disabled"}>Show unpaid ({counts.unpaidCount})</Typography>*/}
                    <FilterHeaderTitle title={"Show unpaid"} count={counts.unpaidCount}/>
                    <FilterWrapper alignItem={"center"}>
                        <SwitchButton checked={filter.showUnpaid}
                                      onChange={(e, val) => onFilterChange("showUnpaid",val)}
                                      size={"large"}/>
                    </FilterWrapper>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", flex: "1 1 ", gap: "5px"}}>
                    {/*<Typography variant={"caption"} color={"text.disabled"}>Show expired ({counts.expiredCount})</Typography>*/}
                    <FilterHeaderTitle title={"Show expired"} count={counts.expiredCount}/>
                    <FilterWrapper alignItem={"center"}>
                        <SwitchButton checked={filter.showExpired}
                                      onChange={(e, val) => onFilterChange("showExpired",val)}
                                      size={"large"}/>
                    </FilterWrapper>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", flex: "1 1 ", gap: "5px"}}>

                </Box>
            </FilterFrame>



            {/*</Box>*/}
        </>
    );
}

export default LicencesListFilter;