// src/theme.js

import { createTheme } from '@mui/material/styles';

let figmaPalette={
    general:{
        general100:"#131523",
        general90:"#333752",
        general80:"#5A607F",
        general70:"#7E84A3",
        general60:"#A1A7C4",
        general50:"#D7DBEC",
        general40:"#E6E9F4",
        general30:"#F5F6FA",
    },
    red:{
        red100:"#F0142F",
        red90:"#F12B43",
        red80:"#F34359",
        red70:"#F45A6D",
        red60:"#F8919D",
        red50:"#FAB3BC",
        red40:"#FCD5D9",
        red30:"#FDE7EA",
    },
    green:{
        green100:"#06A561",
        green90:"#1FD286",
        green80:"#25E191",
        green70:"#48E9A5",
        green60:"#74EFB9",
        green50:"#95F6CC",
        green40:"#C4F8E2",
        green30:"#DAF9EC",
    },
    yellow:{
        yellow100:"#F99600",
        yellow90:"#FFC700",
        yellow80:"#FFD613",
        yellow70:"#FFE45F",
        yellow60:"#FFE582",
        yellow50:"#FFECA3",
        yellow40:"#FFF4C9",
        yellow30:"#FFF9E1",
    },
    system:{
        borderColor:"#D7DBEC",
        background:"#F5F6FA",
        white:"#FFFFFF",
        disabledButton:"#D7DBEC",
        inputBorder:"#D9E1EC",
        inputPlaceholder:"#A1A7C4",
        inputLabel:"#5A607F"
    },
    roles:{
        supervisor:"#5C48B7",
        supervisorSecondary:"#D7D0EE",
        administrator:"#4B9496",
        administratorSecondary:"#D3EAEC",
        installer:"#C9448E",
        installerSecondary:"#F0CDE4",
        support:"#4D9F36",
        supportSecondary:"#D6F4CF",
        viewer:"#F18936",
        viewerSecondary:"#FAE3D1",
    },
    status:{
        charging:"#06A561",
        chargingSecondary:"#C4F8E2",
        charged:"#1E5EFF",
        chargedSecondary:"#D9E4FF",
        available:"#0BA1F5",
        availableSecondary:"#CCF0FF",
        unlicenced:"#F99600",
        unlicencedSecondary:"#FFF4C9",
        waiting:"#E10897",
        waitingSecondary:"#FADDF3",
        disconnected:"#F12B43",
        disconnectedSecondary:"#FDE7EA",
        hold:"#9E712C",
        holdSecondary:"#F1D3A7",
        unassigned:"#5A607F",
        unassignedSecondary:"#F5F6FA",

    }
}
const themeInstance=createTheme({})
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1518,
            xxl: 1718
        },
    },
    palette: {
        primary: {
            ...themeInstance.palette.primary,
            main: '#1E5EFF', // Your primary color
            "ultraLight":"#D9E4FF",
                primary100:"#1E5EFF",
                primary90:"#336DFF",
                primary80:"#4F81FF",
                primary70:"#608DFF",
                primary60:"#89ABFF",
                primary50:"#B6CBFF",
                primary40:"#D9E4FF",
                primary30:"#ECF2FF",
        },
        success:{
          ...themeInstance.palette.success,
          "ultraLight":"#D3EAEC"
        },
        secondary: {
            ...themeInstance.palette.secondary,
            "ultraLight":"#D7D0EE",
            secondary100:"#6E0BD4",
            secondary90:"#8125DF",
            secondary80:"#9645E9",
            secondary70:"#A75EF1",
            secondary60:"#B97FF3",
            secondary50:"#D8B1FF",
            secondary40:"#E9D3FF",
            secondary30:"#F4E9FE",
        },
        error:{
            ...themeInstance.palette.error,
          main:"#F0142F",
            "ultraLight":"#F0CDE4"
        },
        warning:{
          ...themeInstance.palette.warning,
            "ultraLight":"#FAE3D1",
            "yellowLight":"#FFE582"
        },
        text:{
            ...themeInstance.palette.text,
            disabled:"#5A607F",
            "ultraLight":"#F5F6FA"
        },
        borderColor:{
            main:"#D9E1EC"
        },
        ...figmaPalette
    },
    typography: {
        fontFamily: 'Plus Jakarta Sans',
    },
    components:{
        MuiTypography:{
            styleOverrides:{
                h1:{
                    fontFamily: 'Plus Jakarta Sans',
                },
                h2:{
                    fontFamily: 'Plus Jakarta Sans',
                },
                h3:{
                    fontFamily: 'Plus Jakarta Sans',
                },
                h4:{
                    fontFamily: 'Plus Jakarta Sans',

                },
                body1:{
                    fontFamily: 'Plus Jakarta Sans',
                },
                body2:{
                    fontFamily: 'Plus Jakarta Sans',
                },
                caption:{
                    fontFamily:'Plus Jakarta Sans'
                },
                subtitle1:{
                    fontFamily:'Plus Jakarta Sans'
                },
                subtitle2:{
                    fontFamily:'Plus Jakarta Sans'
                }


            },
            variants: [
                {
                    props: { variant: 'Link' },
                    style: {
                        textDecoration:"none",
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight:600,
                        // fontSize:"14px",
                        color:"#1E5EFF",
                        "&:hover":{
                            color:"#0041db",
                        }
                    },
                },
                {
                    props: { variant: 'Upload Link' },
                    style: {
                        textDecoration:"none",
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight:600,
                        fontSize:"0.7rem",
                        color:"#1E5EFF",
                        "&:hover":{
                            color:"#0041db",
                        }
                    },
                }
            ],
        },
        MuiCheckbox:{
            styleOverrides:{
                root:{
                    padding:0,
                    color:"#D7DBEC",
                    '&.Mui-checked': {
                        color: "#1E5EFF",
                    }
                },

            }
        },
        MuiTextField:{
            styleOverrides:{
                root:{
                    fontFamily: 'Plus Jakarta Sans',
                    // fontSize:"13.5px"
                    '& .Mui-disabled': {
                        backgroundColor: figmaPalette.general.general30,
                        color:figmaPalette.general.general60
                    }
                },

            }
        },
        MuiInputBase:{
            styleOverrides:{
                root:{
                    fontFamily: 'Plus Jakarta Sans',
                    // "&  .Mui-disabled ~ .MuiTextField-root":{
                    //     color:"blue",
                    //     // background:"#F5F6FA"
                    //     background:"blue"
                    // }
                },
                input:{
                    padding:"10.5px 14px !important",
                    // fontSize:"16px",
                    fontSize:"13.5px",
                    "&::placeholder":{
                        fontFamily:'Plus Jakarta Sans'
                    }
                }
            },
                variants:[{
                    props: { variant: 'AutocompleteInput' },
                    style: {
                        fontFamily: 'Plus Jakarta Sans',
                        height:"20px"
                    },
                }]

        },
        MuiFormHelperText:{
            styleOverrides:{
                root:{
                    marginLeft:"0px",
                    color:"#F0142F",
                    fontSize:"12px"
                },

            }
        },
        MuiAutocomplete:{
            styleOverrides:{
                root:{
                    height:"42px",
                    // borderRadius:"20px",
                    // background:"red"
                    // ":disabled":{
                    //     background:"blue"
                    // }
                },
                input:{
                    height:"10px",
                    // background:"red"
                },
                inputRoot:{
                    height:"42px",
                    paddingLeft:"0px !important",
                    // background:"blue"
                    // background:"blue"
                },

            }

        },
        MuiButton:{
            styleOverrides:{
                root:{
                    fontFamily:'Plus Jakarta Sans'
                },
            },
        }


    },
});

export default theme;
