import Cookies from 'js-cookie';
import {base64Decode, base64Encode} from "@mui/x-license-pro/encoding/base64";

const USER_NAME="ur__"
const PASSWORD="pw__"
export function setUserCredentials(username="",password=""){
    let path=window.location?.href || ""
    let userName=btoa(username)
    let passWord=btoa(password)
    Cookies.set(USER_NAME, userName, { expires: 7,path })
    Cookies.set(PASSWORD, passWord, { expires: 7,path })
}

export const getUserCredentials = () => {
    let path=window.location?.href || ""
    let user=""
    let password ="";
    try {
        user = atob(Cookies.get(USER_NAME,{path})) || "";
        password = atob(Cookies.get(PASSWORD,{path})) || "";

    }catch (e) {

    }
    let isRemember=!!(user && password)
    return  {userName:user,password,isRemember};
};

export const removeUserCredentials = () => {
    let path=window.location?.href || ""
    Cookies.remove(USER_NAME, { path });
    Cookies.remove(PASSWORD, { path });
};