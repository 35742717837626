import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Box, Divider, Drawer, Toolbar, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom"
import Logout from "../../../../assets/svg-icon/logout.svg"
import {
    allMenuList,
    InstallationMenuList,
    MENU_ID_LIST,
    WithoutInstallationMenu
} from "../../sidebar-helper/menu-id-list";
import {logoutUser, switchParentUser} from "../../../../slice/UserSlice"
import {getCompanyType} from "../../../../helper/helper";
import InstallationIcon from "../../../../assets/svg-icon-for-menu/installation-icon.svg";
import useUser from "../../../../custom-hook/use-user";
import HeaderBox from "./component/header-box";
import DropDownMenuComponent from "./component/drop-down-menu-component";
import MenuButton from "./component/menu-button";


function LogoutButton(props) {
    const dispatch = useDispatch()

    function LogoutUser() {
        dispatch(logoutUser())
    }

    return (<Box variant={"contained"}
                 paddingTop={"2px"}
                 paddingBottom={"2px"}
                 paddingLeft={"16px"}
                 borderRadius={"5px"}
                 sx={{
                     width: "100%",
                     textAlign: "left",
                     minHeight: "37px",
                     display: "flex",
                     alignItems: "center"
                 }}

                 onClick={() => LogoutUser()}> <Avatar
        variant={"square"}
        src={Logout}
        sx={{
            width: 20,
            height: 20,
            bgcolor: "transparent",
            fill: "white"
        }}/><Typography
        sx={{cursor: "pointer", marginLeft: "5px"}} color={"text.disabled"} variant={"caption"}
        margin={"0px 0px"}>Logout</Typography></Box>)
}

const MenuList = {...allMenuList}

const drawerWidth = 250;
const installationRoleArray=["A","S","I","C","V"]
function MenuSidebar(props) {
    const {getCompanyType:gct,getActiveUser}=useUser()
    const role = useSelector(state => (state.authUser.role))
    const authUser = useSelector(state => state.authUser)

    const ActiveUser = getActiveUser()
    let DrawerMenuList = gct() in MenuList ? MenuList[gct()] : []
    if(installationRoleArray.includes(role)){
        if (ActiveUser?.installtionExist) {
            DrawerMenuList = [...DrawerMenuList, InstallationMenuList]
        } else {
            DrawerMenuList = [...DrawerMenuList,WithoutInstallationMenu ]
        }
    }

    const navigate = useNavigate()
    const {pathname} = useLocation();
    const selectedMenuFirstTime = useMemo(() => {
        return Object.keys(MENU_ID_LIST).filter((val) => (MENU_ID_LIST[val].includes(pathname)))
    }, [pathname])
    const [selectedMenu, setSelectedMenu] = useState([...selectedMenuFirstTime])
    useEffect(() => {
        setSelectedMenu([...selectedMenuFirstTime])
    }, [pathname])
    const navigateUser=React.useCallback((path, parentId, childId = "")=> {
        setSelectedMenu([parentId, childId])
        navigate(path)
    },[])

    const dispatch = useDispatch()

    //switch user
    function switchToMainUser() {
        if (authUser.users.length > 1) {
            dispatch(switchParentUser([{}]))
            navigate("/app/about-companies")
        }
    }

    return (
        <>
            {/*return (*/}
            <>
                <Box>
                    <HeaderBox switchToMainUser={switchToMainUser} />
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            background: "#F5F6FA",
                            [`& .MuiDrawer-paper`]: {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                                background: "#F5F6FA"
                            },
                        }}
                    >

                        <Toolbar sx={{background: "#F5F6FA"}}/>

                        <Box sx={{overflow: 'auto'}}>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "5px",
                                justifyContent: "center",
                                marginTop: "5px",
                                background: "#F5F6FA"
                            }}>
                                {/*{console.log(ActiveUser,"ActiveUser::::")}*/}
                                <Box sx={{width: "90%", paddingTop: "10px"}}>
                                    <Typography variant={"h6"}>{ActiveUser?.companyName || ""}</Typography>
                                    <Typography variant={"subtitle2"} sx={{
                                        overflowWrap: " break-word",
                                        wordWrap: "break-word",
                                        wordBreak: " break-word"
                                    }}>{ActiveUser?.city}, {ActiveUser?.region}, {ActiveUser?.country}</Typography>
                                    <Typography variant={"caption"}
                                                sx={{color: "text.disabled"}}>{getCompanyType(ActiveUser?.companyType)}</Typography>

                                </Box>
                                <Divider sx={{width: "95%"}}/>
                                {DrawerMenuList.map((val,index) => (
                                    <>
                                        {
                                            "child" in val ?
                                                <DropDownMenuComponent  key={`${val.id}`} selectedMenu={selectedMenu} navigateUser={navigateUser}
                                                                data={val}/> :
                                                <Box sx={{width: "93%"}}>
                                                    <MenuButton key={`${val.id}`} val={val} selectedMenu={selectedMenu}
                                                                         navigateUser={navigateUser}/>
                                                </Box>
                                        }
                                    </>
                                ))}
                                <LogoutButton/>
                            </Box>
                        </Box>
                    </Drawer>

                </Box>


            </>
        </>
    );
}

export default MenuSidebar;