import React from 'react';
import {Box, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


function DialogHeader(props) {
    const {onClose,title,icon:Ic=false,iconSize,hideClose=false}=props
    return (
        <>
            <Box sx={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                background: "#F5F6FA"
            }}>
                <Typography variant={"h6"} fontWeight={"600"} sx={{display:"table"}}>
                    <Box sx={{display:"flex",alignItems:"center",flexDirection:"row"}} alignItems={"center"} flexDirection={"row"} gap={0.3} > {Ic && <Ic fontSize={iconSize}/>}{title}</Box></Typography>
                {!hideClose && <IconButton onClick={onClose}><CloseIcon/></IconButton>}
            </Box>
        </>
    );
}

export default DialogHeader;