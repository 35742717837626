import {Badge, Box, Typography, useTheme} from "@mui/material";
import React from "react";
function BadgeIcon({haveBadge,count,children,...p}){
    const theme=useTheme()
    return <>{haveBadge?<Badge sx={{" & .MuiBadge-badge": {background: theme.palette.red.red60}}} badgeContent={4} color="secondary">
        {children}
    </Badge>:children}</>
}
function MenuButton(props) {
    let {val, navigateUser, parentId = "", selectedMenu, subMenu = false} = props
    const theme = useTheme()
    let paddingLeft = 16
    if (subMenu) {
        paddingLeft += 8
    }
    let isSelected = selectedMenu.includes(val.id)
    return (<Box variant={"contained"}
                 paddingTop={"2px"}
                 paddingBottom={"2px"}
                 paddingLeft={`${paddingLeft}px`}
                 borderRadius={"5px"}
                 sx={{
                     width: "100%",
                     textAlign: "left",
                     color: selectedMenu.includes(val.id) ? "#fff" : "text.disabled",
                     background: isSelected ? theme.palette.primary.main : "",
                     minHeight: "37px",
                     display: "flex",
                     alignItems: "center"
                 }}
                 onClick={() => navigateUser(val.path, parentId, val.id)}>
        <BadgeIcon count={4} haveBadge={val?.haveBadge || false}>
            {/*<Avatar*/}
            {/*    variant={"square"}*/}
            {/*    src={val.icon}*/}
            {/*    sx={{*/}
            {/*        width: 20,*/}
            {/*        display:isSelected?"none":"",*/}
            {/*        height: 20,*/}
            {/*        bgcolor: "transparent",*/}
            {/*        fill: "white"*/}
            {/*    }}/>*/}
            {/*    <Avatar*/}
            {/*    variant={"square"}*/}
            {/*    src={val.whiteIcon}*/}
            {/*    sx={{*/}
            {/*        width: 20,*/}
            {/*        height: 20,*/}
            {/*        display:!isSelected?"none":"",*/}
            {/*        bgcolor: "transparent",*/}
            {/*        fill: "white"*/}
            {/*    }}/>*/}
            {/*{isSelected?val.whiteIcon:val.icon}*/}
            <span style={{display:!isSelected?"none":"",width:isSelected?"fit-content":"0px",height:isSelected?"fit-content":"0px"}}>
                    {val.whiteIcon}
                </span>
            <span style={{display:isSelected?"none":"",width:!isSelected?"fit-content":"0px",height:!isSelected?"fit-content":"0px"}}>
                    {val.icon}
                </span>
        </BadgeIcon>
        <Typography
            sx={{cursor: "pointer", marginLeft: "10px"}} variant={"caption"}
            margin={"0px 0px"}>{val.name}</Typography></Box>)
}
export default MenuButton