import React, {useEffect, useState} from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";
import LoadingButton from "../../../../components/button-component/loading-button";
import StyledDialog, {StyledDialogCell} from "../../../../components/dialog-component/styled-dialog";
import AboutYellowIcon from "../../../../../assets/icons/about-yellow-icon";
import {getActiveUser} from "../../../../../helper/helper";
import ChargePointApi from "../../../../../apis/charge-points/charge-points-api";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import useUser from "../../../../../custom-hook/use-user";
import {ErrorToast} from "../../../../../Util/util";


function UnassignLicences(props) {
    const {open,onClose,afterSave,row}=props
    const [state,setState]=useState({loading:{unassignLoading:false}})
    const {getToken,getCompanyId,getUserInstallationId}=useUser()
    const {loading}=state
    function onSubmit(){
        setState(prevState => ({...prevState,loading: {...prevState.loading,unassignLoading: true}}))
        let params={}
            params={licenceId:null}
        params.companyId=getCompanyId()
        ChargePointApi.updateChargePoint(getUserInstallationId(),row.chargePointId,{...params},getToken()).then((res)=>{
            toast.success("Licence Unassigned")
            setState(prevState =>({...prevState,loading: {...prevState.loading,unassignLoading: false}}))
            afterSave(row)
        }).catch((e)=>{
            ErrorToast(e)
            setState(prevState =>({...prevState,loading: {...prevState.loading,unassignLoading: false}}))
        })


    }
    useEffect(()=>{
        setState(prevState => ({...prevState,loading: {...prevState.loading,unassignLoading: false}}))
    },[open])

    return (
        <>
            <StyledDialog title={<Stack alignItems={"center"} flexDirection={"row"} gap={0.3} > <AboutYellowIcon fontSize={"24px"}/>Unassign licence</Stack>} open={open} onClose={() => onClose()}>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <Box sx={{display: "flex", gap: 2, flexDirection: "column", width: "25vw"}}>
                        <Box sx={{display:"flex",flexDirection:"column",gap:0.3}}>
                            <Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Licence: <Typography variant={"subtitle2"} fontWeight={"bold"} color={"text.primay"}>{row?.licenceCode}</Typography></Typography>
                            <Typography variant={"subtitle2"} display={"flex"} flexDirection={"row"} gap={1}>Chargepoint: <Typography variant={"subtitle2"} fontWeight={"bold"} color={"text.primay"}>{row?.ocppName}</Typography></Typography>
                        </Box>
                    </Box>
                </StyledDialogCell>
                <Divider/>
                <StyledDialogCell sx={{minWidth:"30vw",paddingTop:2.5,paddingBottom:2.5}}>
                    <LoadingButton loading={loading.unassignLoading} color={"error"}  onClick={() => onSubmit()} label={"Unassign licence"}/>
                </StyledDialogCell>
            </StyledDialog>

        </>
    );
}

export default UnassignLicences;