import React from 'react';
import {Box} from "@mui/material";

function FilterFrame(props) {
    const {children,paddingBottom="10px"}=props
    return (
        <>
            <Box sx={{
                width: "100%",
                display: "flex",
                flex: "fit-content 0",
                flexDirection: "column",
                paddingBottom: paddingBottom,
                gap: "0px"
            }}>
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    flex: "fit-content 0",
                    gap: "10px",
                    paddingTop: "10px"
                }}>
                    {children}
                </Box>
            </Box>
        </>
    );
}

export default FilterFrame;