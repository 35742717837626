import ApiHelper from "../../helper/ApiHelper";

class InvitationApis {

    saveInstallationData(params, token) {
        return ApiHelper.post(`/admin/api/v1/installation`, {...params},{}, {token: token},false);
    }
    updateInstallationData(companyId,params, token) {
        return ApiHelper.put(`/admin/api/v1/company/${companyId}`, {...params}, {},{token: token},false);
    }
    fetchTOUPlan(params,token){
        return ApiHelper.get("/admin/api/v1/touplan",{...params},{token:token},false)
    }
    fetchInstallationData(params, token) {
        return ApiHelper.get(`/admin/api/v1/installation`, {...params}, {token: token},false);
    }
    deleteInstallation(id,params, token) {
        return ApiHelper.delete(`/admin/api/v1/installation/${id}`, {...params},{}, {token: token},false);
    }
    updateInstallationRecord(id,params, token) {
        return ApiHelper.put(`/admin/api/v1/installation/${id}`, {...params},{}, {token: token},false);
    }
    syncTopology(id,params, token) {
        return ApiHelper.post(`/admin/api/v1/installation/${id}/topology/sync`, {...params},{}, {token: token},false);
    }

}

const serviceInstance = new InvitationApis();
Object.freeze(serviceInstance);
export default serviceInstance;