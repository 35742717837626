import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, Route, Routes, useNavigate, Outlet, Navigate, useLocation} from "react-router-dom"
import AboutApi from "../../../apis/authorization/login-api";
import {logoutUser, updateToken} from "../../../slice/UserSlice";
import {Box, CssBaseline, Toolbar} from "@mui/material";
import MenuSidebar from "../layout-component/sidebar/menu-sidebar";
import MainFooter from "../layout-component/main-footer";
import Admin from "../../admin";
import UserIdleTimer from "../idle-timer/user-idle-timer";

function AdminRoutes() {
    return <>
        {/*<Routes>*/}
        <Admin/>
        {/*<Route path={"/admin/*"} element={<Admin/>}/>*/}
        {/*</Routes>*/}
    </>
}
function SecurePath(props) {
    const {authUser} = props
    const dispatch=useDispatch()
    useEffect(() => {
        let fetchAccessTokenIntervel=setInterval(()=>{
            AboutApi.getAccessToken({refreshToken:authUser.refreshToken},null).then((res)=>{
                dispatch(updateToken({token:res.data.token}))
            }).catch((e)=>{
                // dispatch(logoutUser())
            })
        },90000)
        // },900)
        return () => {
            clearInterval(fetchAccessTokenIntervel)
        }
    }, [])
    return (
        <>
            <UserIdleTimer />
            <CssBaseline/>

            <Box sx={{display: 'flex', background: "#F5F6FA"}}>
                <MenuSidebar/>
                <Box component="main" sx={{flexGrow: 1, p: 0, minHeight: "100%",overflow:"hidden"}}>
                    <Toolbar
                        sx={{height: "55px !important", minHeight: "55px !important", maxHeight: "55px !important"}}/>
                    <Box sx={{height: "calc(100vh - 55px)", display: "flex", flexFlow: "column", overflow: "auto"}}>
                        <Box sx={{flex: "1 1", overflow: "hidden"}}>
                            <Box sx={{width:"100%",height:"100%",overflow:"auto"}}>
                            {/*{(authUser.role === "A" || authUser.role === "S" || authUser.role === "V" || authUser.role === "I") && <AdminRoutes/>}*/}
                             <AdminRoutes/>
                            </Box>
                        </Box>
                        <Box sx={{flex: "fit-content 0"}}>
                            <MainFooter/>
                        </Box>
                    </Box>

                </Box>
            </Box>

        </>
    )
}
function AuthenticatedRouteRedirect(){
    const location=useLocation()
    if(location.pathname==="/app" || location.pathname==="/"){
        return <Navigate to={"/app/about-companies"}/>
    }
    return <Outlet/>
}
function ProtectedRoutes(){
    const isAuthenticated = useSelector((state) => (state.authUser.isAuthenticated))


    return isAuthenticated?<AuthenticatedRouteRedirect/>:<Navigate to={"/login"}/>
}
function PrivateRoutes(props) {
    const authUser = useSelector((state) => (state.authUser))
    return (
        <>
            <Routes>
                <Route path={"/"} element={<ProtectedRoutes />}>
                    <Route path={"/app/*"} element={<SecurePath authUser={authUser} />}/>
                    <Route path={"*"} element={<>Not Found</>}/>
                </Route>
            </Routes>

        </>
    );
}

export default PrivateRoutes;