import ApiHelper from "../../helper/ApiHelper";

class ChargePointApis {

    getChargePointData(installationId,params, token) {
        return ApiHelper.get(`/admin/api/v1/installation/${installationId}/chargepoint`, {...params}, {token: token},false);
    }
    addChargePoint(installationId,params,token){
        return ApiHelper.post(`/admin/api/v1/installation/${installationId}/chargepoint`, {...params},{}, {token: token},false);
    }
    updateChargePoint(installationId,id,params,token){
        return ApiHelper.put(`/admin/api/v1/installation/${installationId}/chargepoint/${id}`, {...params},{}, {token: token},false);
    }
    fetchChargePoint(installationId,chargePointId,params,token){
        return ApiHelper.get(`/admin/api/v1/installation/${installationId}/chargepoint/${chargePointId}`, {...params}, {token: token},false);
    }
    deleteChargePoint(installationId,chargePointId,params,token){
        return ApiHelper.delete(`/admin/api/v1/installation/${installationId}/chargepoint/${chargePointId}`, {...params},{}, {token: token},false);
    }
    fetchChargepointTimeLineList(params,token){
        return ApiHelper.get("/admin/api/v1/chargepointstate",{...params}, {token:token},false)
    }
    syncTopology(installationId,params, token) {
        return ApiHelper.post(`/admin/api/v1/installation/${installationId}/topology/sync`, {...params}, {},{token: token},false);
    }


}

const serviceInstance = new ChargePointApis();
Object.freeze(serviceInstance);
export default serviceInstance;