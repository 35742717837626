import React, {useEffect, useState} from 'react';
import {Avatar, Box, CircularProgress, TextField, Typography} from "@mui/material";
import {getALlQueryParams, isEmailValid, isStrongPassword} from "../../../helper/helper";
import KeyIcon from "../../../assets/icons/forgot-password/key-icon.svg";
import LoadingButton from "../../components/button-component/loading-button";
import StyledLinkComponent from "../../../components/styled-link-component";
import CommonRightSidebar from "../components/common-right-sidebar";
import ForgetPasswordSuccess from "./forget-password-success";
import ExpireLinkError from "./expire-link-error";
import ForgotPasswordApi from "../../../apis/authorization/forgot-password-api";
import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom";
import {ErrorToast, REQUIRED_MESSAGE} from "../../../Util/util";
import {any} from "prop-types";

function AddNewPassword(props) {
    const [state, setState] = useState({loading: false,tokenCheckLoading:true,formData:{password:"",confirmPassword:""}, error: {password: false,confirmPassword:false}})
    const {error, loading,formData,tokenCheckLoading} = state
    const [passwordSaved,setPasswordSaved]=useState(false)
    const [linkExpired,setLinkExpired]=useState(false)
    const queryParams=getALlQueryParams()
    useEffect(()=>{

        if(queryParams.get("token")){
            let params={token:queryParams.get("token")}
            ForgotPasswordApi.validateToken(params).then((res)=>{
                setState(prevState => ({...prevState,tokenCheckLoading: false}))

            }).catch((e)=>{
                ErrorToast(e)
                setState(prevState => ({...prevState,tokenCheckLoading: false}))
                setLinkExpired(true)
            })
        }else{
            setLinkExpired(true)
            setState(prevState=>({...prevState,tokenCheckLoading:false}))
        }

    },[])
    function validateForm(){
        let anyError=false
        let errorObj={password:false,confirmPassword:false}
        if(formData.password==="" ||formData.password!==formData.confirmPassword || !isStrongPassword(formData.password)){
            if(formData.password===""){
                anyError=true
                errorObj.password=REQUIRED_MESSAGE
                errorObj.confirmPassword=REQUIRED_MESSAGE
            }else if(formData.password !==formData.confirmPassword){
                anyError=true
                errorObj.password="Password must be same"
                errorObj.confirmPassword="Password must be same"
            }else if(!isStrongPassword(formData.password)){
                anyError=true
                errorObj.password="Required at least one uppercase, lowercase, number, special character and minimum 8 characters."
                errorObj.confirmPassword="Required at least one uppercase, lowercase, number, special character and minimum 8 characters."
            }
        }
        setState(prevState => ({...prevState,error: {...prevState.error,...errorObj}}))
        // let validEmail=isStrongPassword(formData.password)?false:"Required at least one uppercase, lowercase, number, special character and minimum 8 characters."
        // setState(prevState => ({...prevState,error: {...prevState.error,password: !validEmail}}))
        return !anyError
    }
    function onSubmit() {
        if(validateForm()){
            setState(prevState=>({...prevState,loading:true}))
            let params={token:queryParams.get("token"),password:formData.password}
            ForgotPasswordApi.resetPassword(params).then((res)=>{
                setPasswordSaved(true)
                setState(prevState=>({...prevState,loading:false}))
            }).catch((e)=>{
                ErrorToast(e)
                setState(prevState=>({...prevState,loading:false}))
            })
        }

    }
    function onFormDataChange(field,value) {
        setState(prevState => ({...prevState,formData: {...prevState.formData,[field]:value}}))
    }
    if(tokenCheckLoading){
        return <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
            <CircularProgress />
        </Box>
    }
    if(linkExpired){
        return <ExpireLinkError />
    }
    if(passwordSaved){
        return  <ForgetPasswordSuccess />
    }
    return (
        <>
            <Box sx={{display: "flex", flexFlow: "row", height: "100vh"}}>
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
                     flex={"1 1"}>
                    <Box sx={{display: "flex", justifyContent: "center", flexDirection: "column", gap: "15px",width:"60%"}}
                         flex={"1 1"}>
                        <Box flex={"fit-content 0"} display={"flex"} gap={"5px"} alignItems={"center"}>
                            <Avatar src={KeyIcon} variant="square"/>
                            <Typography variant={"h5"} >Reset password</Typography>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"}  flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>New password</Typography>
                            <TextField onChange={(e)=>onFormDataChange("password",e.target.value)} error={error.password} value={formData.password} helperText={error.password} placeholder={"Enter your password"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"}  flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Confirm new password</Typography>
                            <TextField onChange={(e)=>onFormDataChange("confirmPassword",e.target.value)} error={error.confirmPassword} value={formData.confirmPassword} helperText={error.confirmPassword} placeholder={"Confirm new password"}/>
                        </Box>


                        <Box flex={"fit-content 0"} display={"flex"} gap={"20px"}>
                            <LoadingButton
                                loading={loading} onClick={onSubmit} size={"large"} sx={{textTransform: 'capitalize', width: "100%"}} color={"primary"}
                                variant={"contained"} label={"Reset password"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} justifyContent={"space-between"} gap={"5px"}>

                            <Box display={"flex"} gap={"4px"}>
                                {/*<StyledLinkComponent path={"/login"}>Back to login</StyledLinkComponent>*/}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <CommonRightSidebar/>


            </Box>
        </>
    );
}

export default AddNewPassword;