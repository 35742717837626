import React, {Suspense} from 'react';
import LinearIndeterminate from "../../components/layout-component/linear-progress-bar";
import {Route, Routes} from "react-router-dom";
const VarianHome=React.lazy(()=> import("./varian-home"))

function Index(props) {
    return (
        <>
            <Suspense fallback={<LinearIndeterminate/>}>
                <Routes>
                    <Route path={"/vh/:id"} element={<VarianHome/>}/>
                    <Route path={"/vh/*"} element={<VarianHome/>}/>
                </Routes>
            </Suspense >
        </>
    );
}

export default Index;