import ApiHelper from "../../helper/ApiHelper";

class ForgotPasswordApi {

    sendForgotPasswordRequest(params, token) {
        return ApiHelper.post("/admin/api/v1/login/reset", {...params},{}, {token: token},false);
    }
    validateToken(params, token) {
        return ApiHelper.get("/admin/api/v1/login/reset", {...params}, {token: token},false);
    }
    resetPassword(params, token) {
        return ApiHelper.put("/admin/api/v1/login/reset", {...params},{}, {token: token},false);
    }


}

const serviceInstance = new ForgotPasswordApi();
Object.freeze(serviceInstance);
export default serviceInstance;