import ApiHelper from "../../helper/ApiHelper";

class RegistrationApis {

    fetchRegistration(params, token) {
        return ApiHelper.get("/admin/api/v1/teammember/invite", {...params}, {token: token},false);
    }
    saveRegistration(memberId,params, token) {
        return ApiHelper.put(`/admin/api/v1/teammember/${memberId}`,{...params}, {}, {token: token},false);
    }

}

const serviceInstance = new RegistrationApis();
Object.freeze(serviceInstance);
export default serviceInstance;