import ApiHelper from "../../helper/ApiHelper";

class InvoicesApi {

    purchaseLicences(installationId,params, token) {
        return ApiHelper.post(`/admin/api/v1/installation/${installationId}/licence`, {...params},{},{token: token},false);
    }
    fetchLicencesList(installationId,params, token) {
        return ApiHelper.get(`/admin/api/v1/installation/${installationId}/licence`, {...params},{token: token},false);
    }
    deleteLicence(installationId,id,params, token) {
        return ApiHelper.delete(`/admin/api/v1/installation/${installationId}/licence/${id}`, {...params},{},{token: token},false);
    }
    updateLicence(installationId,id,params,token){
        // /admin/api/v1/installation/76/licence/{licencecode}
        return ApiHelper.put(`/admin/api/v1/installation/${installationId}/licence/${id}`, {...params},{},{token: token},false);
    }
    autoAllocateLicence(installationId,params,token){
        // /admin/api/v1/installation/76/licence/{licencecode}
        return ApiHelper.post(`/admin/api/v1/installation/${installationId}/licence/allocate`, {...params},{},{token: token},false);
    }
    syncTopology(installationId,params, token) {
        return ApiHelper.post(`/admin/api/v1/installation/${installationId}/topology/sync`, {...params}, {},{token: token},false);
    }



}

const serviceInstance = new InvoicesApi();
Object.freeze(serviceInstance);
export default serviceInstance;