import React from 'react';
import {useSelector} from "react-redux";

function PermissionHelper(props) {
    const authUser = useSelector((state) => state.authUser);
    const getActiveUser=()=>{
        return authUser.users[0]
    }
    const getLoginCompany=()=>{
        // return false
        return authUser.users[authUser.users.length - 1]
    }
    const blackListPermission=(notActiveAllowedCompanyType=[],notAllowedLoginCompanyType=[],notAllowedUserType=[])=>{
        let activeCompany=getActiveUser()
        let activeCompanyType=activeCompany.companyType
        let loginCompany=getLoginCompany()
        let loginCompanyType=loginCompany.companyType
        let userRole=authUser.user?.role || ''
        if((notActiveAllowedCompanyType.length !==0 ? !notActiveAllowedCompanyType.includes(activeCompanyType):true) && (notAllowedLoginCompanyType.length !==0 ? !notAllowedLoginCompanyType.includes(loginCompanyType):true) && (notAllowedUserType.length !==0 ? !notAllowedUserType.includes(userRole):true)){
            return true
        }
        return false
    }
    return {
        blackListPermission
    }
}

export default PermissionHelper;