export const VARIABLE_GRID="V"
export const CUSTOMER="R"
export const CONTRACTOR="C"
export const GROUP="G"
export const DEALER="D"

// switch (type){
//     case "V":
//         return "Variablegrid"
//     case "R":
//         return "Customer"
//     case "C":
//         return "Contractor"
//     case "G":
//         return "Group"
//     case "D":
//         return "Dealer"
//     default:
//         return ""
// }