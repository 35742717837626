import React from 'react';
import {Box, Typography, useTheme} from "@mui/material";

function MainFooter(props) {
    const theme=useTheme()
    return (
        <>
            <Box sx={{display:'flex',paddingY:1,paddingX:1,background:"transparent",borderTop:"1px solid #D7DBEC",gap:2}}>
                <Typography variant={"caption"} color={"text.disabled"} flex={"1 1 fit-content"}>© Variablegrid Adaptive Power Inc.</Typography>
                <Typography variant={"caption"} color={"text.disabled"} flex={"fit-content 0 fit-content"}>Support</Typography>
                <Typography variant={"caption"} color={"text.disabled"} flex={"fit-content 0 fit-content"}>Terms and conditions</Typography>
                <Typography variant={"caption"} color={"text.disabled"} flex={"fit-content 0 fit-content"}>Privacy Policy</Typography>
            </Box>
        </>
    );
}

export default MainFooter;