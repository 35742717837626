import React, {useEffect, useState} from 'react';
import {Avatar, Box, Checkbox, CircularProgress, FormHelperText, TextField, Typography} from "@mui/material";
import UserAdd from "../../../assets/svg/user-add.svg";
import StyledLinkComponent from "../../../components/styled-link-component";
import PasswordInputField from "../login/components/password-input-field";
import {isEmailValid, isStrongPassword} from "../../../helper/helper";
import LoadingButton from "../../components/button-component/loading-button";
import {useLocation, useNavigate} from 'react-router-dom';
import CommonRightSidebar from "../components/common-right-sidebar";
import RegistrationApi from "../../../apis/authorization/registration-api"
import LogoImage from "../../../assets/svg/Frame 37912.svg";
import CodeErrorImage from "../../../assets/svg/payment-unsuccessfull.svg";
import {toast} from "react-toastify";
import {ErrorToast, REQUIRED_MESSAGE} from "../../../Util/util";

function CodeError(){
    return <>
        <Box sx={{display: "flex", flexFlow: "row", minHeight: "100vh",maxHeight:"fit-content"}}>
            <Box flex={"1 1"} sx={{
                position: "relative",
                display: "flex",
                background: "#F6FAFF",
                // paddingX: "5px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection:"column",
                gap:"10px"
            }}>
                <img style={{position: "absolute", width: "130px", left: "15px", top: "15px", zIndex: 12}}
                     src={LogoImage}/>
                <img src={CodeErrorImage}/>
                <Typography variant={"h5"} sx={{color:"#5A607F"}}>Not A valid Link</Typography>
            </Box>
        </Box>
    </>
}


function CreateNewAccount(props) {
    const location = useLocation()
    const navigate = useNavigate()
    let formDataList={
        companyName:"",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        reEnterPassword: "",
        iAgree: false,
        teamMemberId:null
    }
    // if(location.state===null){
    //     navigate("../invitation")
    //     // return <></>
    // }else{
    //     formDataList={
    //         ...formDataList,
    //         ...location.state.companyData
    //     }
    // }

    const [state, setState] = useState({

        strongPasswordError: false,
        error: {
            firstName: false,
            lastName: false,
            email: false,
            phoneNumber: false,
            password: false,
            reEnterPassword: false,
            iAgree: false
        },
        formData: {
            ...formDataList
        },
        loading:{mainLoading:true,saveLoading:false},
        token:null,
        codeError:false
    })

    const {error, formData, strongPasswordError,loading} = state

    function onFormDataChange(field, value) {
        // if (field === "phoneNumber" && ((value.length === 1 && value === "+") ||isPhoneNumber(value) )) {
        //     return
        // }
        setState(prevState => ({...prevState, formData: {...prevState.formData, [field]: value}}))
    }


    function validateForm() {
        let errorObj = {
            firstName: false,
            lastName: false,
            email: false,
            phoneNumber: false,
            password: false,
            reEnterPassword: false,
            iAgree: false
        }
        let strongPasswordError = false
        let error = false
        if (formData.firstName.trim() === "") {
            error = true
            errorObj.firstName = true
        }
        if (formData.lastName.trim() === "") {
            errorObj.lastName = true
            error = true
        }
        if (!isEmailValid(formData.email)) {
            errorObj.email = true
            error = true
        }
        if (formData.phoneNumber.trim() === "") {
            errorObj.phoneNumber = true
            error = true
        }
        // if(formData.password.trim() !==)
        if (formData.password === "" || !isStrongPassword(formData.password) || formData.password !== formData.reEnterPassword) {
            error = true
            if(formData.password===""){
                errorObj.password=REQUIRED_MESSAGE
                errorObj.reEnterPassword=REQUIRED_MESSAGE
            }else    if(formData.password !== formData.reEnterPassword){
                errorObj.password="Password must be same"
                errorObj.reEnterPassword="Password must be same"
            }
            else if(!isStrongPassword(formData.password)){
                errorObj.password="Required at least one uppercase, lowercase, number, special character and minimum 8 characters."
                errorObj.reEnterPassword="Required at least one uppercase, lowercase, number, special character and minimum 8 characters."
            }
        }
        // if (formData.password.trim() === "" || !isStrongPassword(formData.password) || formData.password !== formData.reEnterPassword) {
        //     if (!isStrongPassword(formData.password)) {
        //         strongPasswordError = true
        //     }
        //     errorObj.password = true
        //     error = true
        // }
        // if (formData.reEnterPassword.trim() === "" || !isStrongPassword(formData.password) || formData.password !== formData.reEnterPassword) {
        //     errorObj.reEnterPassword = true
        //     error = true
        // }
        if (!formData.iAgree) {
            errorObj.iAgree = true
            error = true
        }
        setState(prevState => ({...prevState, strongPasswordError, error: {...prevState.error, ...errorObj}}))
        return error

    }

    function onFormSubmit() {

        if (!validateForm()) {
            setState(prevState => ({...prevState,loading: {...prevState.loading,saveLoading: true}}))
            const saveData={
                firstName:formData.firstName,
                lastName:formData.lastName,
                email:formData.email,
                password:formData.password,
                telephone:formData.phoneNumber,
                status: "E"
            }
            RegistrationApi.saveRegistration(formData.teamMemberId,{...saveData},state.token).then((res)=>{
                setState(prevState => ({...prevState,loading: {...prevState.loading,saveLoading: false}}))
                navigate("./create-account-success")
            }).catch((e)=>{
                ErrorToast(e)
            })
            // setState(prevState => ({...prevState, loadingButton: true}))
            // navigate("../create-account-success")

        }
    }

    function checkItsNumber(e) {
        // console.log(isPhoneNumber(e.target.value),e.target.value,"VIEwValue:::")
        // if(isPhoneNumber(e.target.value)){
        //     e.preventDefault()
        // }
    }


    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        if( searchParams.has('invitationCode')){
            const invitationCode=searchParams.get('invitationCode')
            let params={invitationCode:invitationCode}
            RegistrationApi.fetchRegistration(params).then((res)=>{
                const resData=res.data.teamMembers[0]
                let formData={...formDataList}
                formData.email=resData?.email || ""
                formData.firstName=resData?.firstName || ""
                formData.lastName=resData?.lastName || ""
                formData.phoneNumber=resData?.telephone || ""
                formData.companyName=resData?.companyName || ""
                formData.teamMemberId=resData?.teamMemberId || ""
                let token=res.data?.token || null
                setState(prevState => ({...prevState,token: token,formData: {...prevState.formData,...formData},loading: {...prevState.loading,mainLoading: false}}))
            }).catch((e)=>{
                setState(prevState => ({...prevState,codeError: true,loading: {...prevState.loading,mainLoading: false}}))
            })
        }

    },[])

    if(loading.mainLoading){
        return <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
            padding: "15px",
            flexDirection: "column"
        }}>
            <CircularProgress/>
        </Box>
    }
    if(state.codeError){
        return <CodeError />
    }
    return (
        <>
            <Box sx={{display: "flex", flexFlow: "row", minHeight: "100vh",maxHeight:"fit-content"}}>
            <Box sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: "70px"
            }}
                 flex={"1 1"}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "10px", width: "60%"}}>
                    <Box flex={"fit-content 0"} display={"flex"} gap={"5px"} alignItems={"center"}>
                        <Avatar src={UserAdd} variant="square"/>
                        <Typography variant={"h5"}>Create an account</Typography>
                    </Box>
                    <Box flex={"fit-content 0"} paddingTop={"20px"}>
                        <Typography variant={"body2"}>Company name:</Typography>
                        <Typography variant={"h6"}>{formData.companyName}</Typography>
                    </Box>
                    <Box flex={"fit-content 0"}>
                        <Typography variant={"body2"} display={"flex"} gap={"5px"}>If the company name is incorrect,
                            please <StyledLinkComponent isLink={false}>contact us</StyledLinkComponent></Typography>
                    </Box>

                    <Box sx={{width: "100%", display: "flex", gap: "15px", flexDirection: "column", marginTop: "15px"}}>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>First Name</Typography>
                            <TextField onChange={(e) => onFormDataChange("firstName", e.target.value)}
                                       error={error.firstName}
                                       value={formData.firstName}
                                       helperText={error.firstName ? "User with this email doesn't exist" : ""}
                                       placeholder={"Enter First Name"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Last Name</Typography>
                            <TextField onChange={(e) => onFormDataChange("lastName", e.target.value)}
                                       error={error.lastName}
                                       value={formData.lastName}
                                       helperText={error.lastName ? "User with this email doesn't exist" : ""}
                                       placeholder={"Enter Last Name"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Email</Typography>
                            <TextField onChange={(e) => onFormDataChange("email", e.target.value)} error={error.email}
                                       value={formData.email}
                                       helperText={error.email ? "User with this email doesn't exist" : ""}
                                       placeholder={"example@gmail.com"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Phone Number</Typography>
                            <TextField
                                onChange={(e) => onFormDataChange("phoneNumber", e.target.value)}
                                error={error.phoneNumber}
                                value={formData.phoneNumber}
                                // onKeyDown={(e)=>checkItsNumber(e)}
                                helperText={error.phoneNumber ? "Incorrect phone number" : ""}
                                placeholder={"+250 555 0199"}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Password</Typography>
                            <PasswordInputField name={"Password"}
                                // errorMassage={(!strongPasswordError && error.password) ? "Passwords don't match" : "Password Must Be Strong"}
                                errorMassage={error.password}
                                // instruction={}

                                onChange={(e) => onFormDataChange("password", e.target.value)}
                                value={formData.password} error={!!error.password }/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Typography variant={"body2"}>Confirm password</Typography>
                            <PasswordInputField name={"rePassword"} errorMassage={error.reEnterPassword}
                                                onChange={(e) => onFormDataChange("reEnterPassword", e.target.value)}
                                                value={formData.reEnterPassword} error={!!error.reEnterPassword}/>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                            <Box display={"flex"} gap={"4px"}>
                                <Checkbox checked={formData.iAgree}
                                          onChange={(e, val) => onFormDataChange("iAgree", val)}/>
                                <Typography variant={"body2"}>I agree to the <StyledLinkComponent path={""}>Terms &
                                    Conditions</StyledLinkComponent> and <StyledLinkComponent path={""}>Privacy
                                    policy</StyledLinkComponent></Typography>
                            </Box>
                            {error.iAgree &&
                            <FormHelperText>You must accept terms & conditions and privacy police< /FormHelperText>}
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} flexDirection={"column"} gap={"5px"}>
                        </Box>
                        <Box flex={"fit-content 0"} display={"flex"} gap={"20px"} paddingBottom={"50px"}>
                            <LoadingButton onClick={onFormSubmit} loading={loading.saveLoading} label={"Register"}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
                <CommonRightSidebar/>
            </Box>
        </>
    );
}

export default CreateNewAccount;