// src/hooks/useUser.js
import {useSelector} from 'react-redux';
import {VARIABLE_GRID} from "../Util/company-types-constant";
import {CUSTOMER_SUPPORT, VIEWER, VIEWER_SUPPORT} from "../Util/user-types-constant";

const useUser = () => {
    const authUser = useSelector((state) => state.authUser);
    const getActiveUser=()=>{
        return authUser.users[0]
    }
    const getParentUser=()=>{
        if(authUser.users.length && authUser.users.length!==1){
            let usersLength=authUser.users.length
            console.log(usersLength,authUser.users,"usersLength::::")
            return authUser.users[1]
        }
        return null
    }
    const getUserInstallationId=()=>{
        let active=getActiveUser()
        return active.installation?.id || null
    }
    const getUserInstallationCode=()=>{
        let active=getActiveUser()
        // return active.userInstallation?.installationCode || null
        return active?.installation?.installationCode || null
    }
    const getUserTimeZone=()=>{
        let active=getActiveUser()
        return active.timezone
    }
    const getToken=()=>{
        return authUser.token
    }
    const getRegionId=()=>{
        let active=getActiveUser()
        return active.regionId
    }
    const getCompanyType=()=>{
        let active=getActiveUser()
        return active.companyType
    }
    const getInstallationPrefix=()=>{
        let active=getActiveUser()
        return active?.installationPrefix || ""
    }
    const getCompanyId=()=>{
        let active=getActiveUser()
        return active?.companyId?.toString() || ""
    }
    const getUserInstallationInfo=()=>{
        let active=getActiveUser()
        // return active.userInstallation
        return active.installation || {}
    }
    const getUserBillingStatus=()=>{
        let active=getActiveUser()
        // return active.userInstallation
        return active.billingEnabled
    }
    const getUserList=()=>{
        return authUser?.users || []
    }
    const getUserRole=()=>{
        return authUser?.role || ""
    }
    const userCURDPermission=()=>{
        // return false
        return authUser?.role !==CUSTOMER_SUPPORT && authUser?.role !==VIEWER 
    }
    const getLoginCompany=()=>{
        // return false
        return authUser.users[authUser.users.length - 1]
    }
    return {
        authUser,
        getCompanyId,
        getParentUser,
        getActiveUser,
        getUserInstallationId,
        getUserTimeZone,
        getToken,
        getRegionId,
        getCompanyType,
        getInstallationPrefix,
        getUserInstallationInfo,
        getUserInstallationCode,
        getUserBillingStatus,
        getUserList,
        getUserRole,
        userCURDPermission,
        getLoginCompany
    };
};

export default useUser;
